<template>
  <div class="container" >
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            class="navbar-nav mx-auto mb-2 mb-lg-0 d-flex justify-content-center nav-ul-li w-100"
          >
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <!-- <li class="nav-item">
                  <a class="nav-link" href="#review">Home</a>
                </li> -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul
                class="dropdown-menu"
                style="width: 17rem"
                aria-labelledby="navbarDropdown"
              >
                <router-link to="/aboutUS" class="nav-link"
                  >Welcome</router-link
                >
                <router-link to="/OurProfessionalCleaners" class="nav-link"
                  >Our Professional Cleaners and Careers</router-link
                >
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/deepClean" class="dropdown-item"
                  >Deep Clean</router-link
                >
                <router-link to="/StandardClean" class="dropdown-item"
                  >Standard Clean</router-link
                >
                <router-link to="/MoveOut" class="dropdown-item"
                  >Move Out and Move in Clean</router-link
                >
                <router-link to="/VacationRentalClean" class="dropdown-item"
                  >Vacation Rental Clean (Airbnb,Vrbo,etc.)</router-link
                >
                <router-link to="/VirusandJerms" class="dropdown-item"
                  >Virus and Germs Disinfection + Sanitization</router-link
                >
                <router-link to="/servicenotoffered" class="dropdown-item"
                  >Services Not Offered</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#review">Reviews</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client Resources
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/faqs" class="dropdown-item p-2"
                  >FAQs</router-link
                >

                <router-link to="/checkoutListing" class="dropdown-item p-2"
                  >Cleaning Checklists</router-link
                >
                <router-link to="/privacy-policy" class="dropdown-item p-2"
                  >Privacy Policy</router-link
                >
                <router-link to="/terms" class="dropdown-item p-2"
                  >Terms of Service</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link"
                >Contact Us</router-link
              >
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/client/home/login"
              >
                <button class="btn login-col" type="button">Login</button>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/home/start"
              >
                <button class="btn book-col" type="button">Book</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="mt-5" style="background-color: #F4EFE9">
      <strong class="service mt-5">WHAT SERVICES AREN'T INCLUDED?</strong>
      <p class="text-center mt-4">
        Our goal is always to leave our clients satisfied. There are, unfortunately, a few
        things we can not do in order to protect the health and safety of our
        well-vetted, experienced and bonded employees. These are:
      </p>
      <div class="row mt-5">
        <div class="col-md-3">
          <img class="washing" src="../assets/Photos/washing-machine.png" alt="" />
          <p class="text-center">Laundary</p>
        </div>
        <div class="col-md-3">
         <img class="washing" src="../assets/Photos/ladder.png" alt="" />
         
          <p class="text-center">Climbing on Ladders</p>
        </div>
        <div class="col-md-3">
         <img class="washing" src="../assets/Photos/biohazard-sign.png" alt="" />
         
          <p class="text-center">
            Climbing on bodily fluids, mold, toy, pet waste, or other biohazard
          </p>
        </div>
        <div class="col-md-3">
         <img class="washing" src="../assets/Photos/dish (1).png" alt="" />
        
         
          <p class="text-center">Dish washing</p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-3">
          <img class="washing" src="../assets/Photos/gears.png" alt="" />
          <p class="text-center">Mold Removal</p>
        </div>
        <div class="col-md-3">
          <img class="washing" src="../assets/Photos/tshirt.png" alt="" />
          <p class="text-center">Deep Stain Removal</p>
        </div>
        <div class="col-md-3">
          <img class="washing" src="../assets/Photos/insects.png" alt="" />
          <p class="text-center">
            Insect and Pest Removal
          </p>
        </div>
        <div class="col-md-3">
          <img class="washing" src="../assets/Photos/shopping-bag.png" alt="" />
          <p class="text-center">Shopping/Errands</p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-3">
          <img class="washing" src="../assets/Photos/window.png" alt="" />
          <p class="text-center">Exterior Windows</p>
        </div>
        <div class="col-md-3">
          <img class="washing" src="../assets/Photos/window (1).png" alt="" />
          <p class="text-center">Cleaning Blinds</p>
        </div>
        <div class="col-md-3">
          <img class="washing" src="../assets/Photos/bulldozer.png" alt="" />
          <p class="text-center">
          Move or Lift Anything Over 25 Pounds
          </p>
        </div>
        <div class="col-md-3">
          <img class="washing" src="../assets/Photos/paint-bucket.png" alt="" />
          <p class="text-center">Pick-up or Remove Clutter</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  path: "faqs",
  component: {},
};
</script>

<style scoped>
.flex-center {
  align-items: center;
}
.accordion-button {
  margin-bottom: 10px;
}
.accordion-body {
  margin-top: 15px;
  padding: 25px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 25px -3px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.circle-icon {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d3c795;
  border: 5px solid #d3c795;
  color: #fff;
  margin-left: -20px;
  margin-right: 10px;
  transform: scale(1.2);
}
.accordion-item {
  border: 0px !important;
}
.accordion-button:not(.collapsed) {
  border: 0px !important;
  color: #b69c35;
  background-color: #ffffff;
  box-shadow: inset 0 0px 0 rgb(0 0 0 / 13%);
}
.service {
  text-align: center;
  font-size: 22px;
}
.washing {
  width: 70px;
}
</style>

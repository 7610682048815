<template>
  <div id="app">
  <!-- <div id="nav">
  </div> -->
    <!-- <Landing /> -->
    <router-view></router-view>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "App",
  components: {
    },
};
</script>
<style>
#app {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}
.vm--modal {
  margin: auto !important;
  left: 0px !important;
  top: null !important;
  overflow: scroll !important;
}

@media only screen and (max-width: 600px) {
  .vm--modal {
    width: 80vw !important;
  }
}

.job-modal ::-webkit-scrollbar {
  display: none;
}

.button {
  margin-bottom: 20px;
}

.services .formulate-input-element .formulate-input-element--checkbox {
  display: inline-block !important;
  /* float: right !important; */
  margin-right: 20px;
}

.services {
  font-size: 18px;
}

.login-form input[type="text"] {
  font-size: 16px;
  width: 100%;
  height: 30px;
  margin: auto;
}
</style>

<template>
  <div class="container left-div">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            class="navbar-nav mx-auto mb-2 mb-lg-0 d-flex justify-content-center nav-ul-li w-100"
          >
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <!-- <li class="nav-item">
                  <a class="nav-link" href="#review">Home</a>
                </li> -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul
                class="dropdown-menu"
                style="width: 17rem"
                aria-labelledby="navbarDropdown"
              >
                <router-link to="/aboutUS" class="nav-link"
                  >Welcome</router-link
                >
                <router-link to="/OurProfessionalCleaners" class="nav-link"
                  >Our Professional Cleaners and Careers</router-link
                >
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/deepClean" class="dropdown-item"
                  >Deep Clean</router-link
                >
                <router-link to="/StandardClean" class="dropdown-item"
                  >Standard Clean</router-link
                >
                <router-link to="/MoveOut" class="dropdown-item"
                  >Move Out and Move in Clean</router-link
                >
                <router-link to="/VacationRentalClean" class="dropdown-item"
                  >Vacation Rental Clean (Airbnb,Vrbo,etc.)</router-link
                >
                <router-link to="/VirusandJerms" class="dropdown-item"
                  >Virus and Germs Disinfection + Sanitization</router-link
                >
                <router-link to="/servicenotoffered" class="dropdown-item"
                  >Services Not Offered</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#review">Reviews</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client Resources
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/faqs" class="dropdown-item p-2"
                  >FAQs</router-link
                >

                <router-link to="/checkoutListing" class="dropdown-item p-2"
                  >Cleaning Checklists</router-link
                >
                <router-link to="/privacy-policy" class="dropdown-item p-2"
                  >Privacy Policy</router-link
                >
                <router-link to="/terms" class="dropdown-item p-2"
                  >Terms of Service</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link"
                >Contact Us</router-link
              >
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/client/home/login"
              >
                <button class="btn login-col" type="button">Login</button>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/home/start"
              >
                <button class="btn book-col" type="button">Book</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-5">
        <img
          class="im-1 mt-5"
          src="../assets/Photos/deep.jpg"
          alt=""
        />
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-6">
        <h2 class="mt-3" style="font-size: 22px; font-weight:600;text-align:left;">What is a deep clean?</h2>
        <p>
          A deep clean service is for all new Clean Influence - DMV clients or existing
          clients whose homes have not been professionally cleaned in the past eight
          weeks. This service grants our professional cleaners the opportunity to pay
          extra attention to trouble-spots and put in a little more elbow-grease. Deep
          cleans are designed to get your home into pristine condition by thoroughly
          removing the dust, dirt, and grime that often accumulates in neglected and
          overlooked areas. In every home there are some spots that are easy to miss
          because they are out of sight or simply difficult to reach. It is easy to forget
          or overlook these areas when cleaning your own home. Consequently, when left
          unaddressed for too long, these neglected areas can cause health issues or pest
          problems. In addition, the longer the areas remain unaddressed, the more
          difficult they become to clean. At Clean Influence - DMV we know exactly how and
          what is needed to get these tough areas taken care of. Our professional cleaning
          staff will expertly address the overlooked and neglected areas using our
          Ci-Pristine Cleaning System (hyperlink) to get your home in its best condition.
          Once we’ve visited and cleaned those areas properly, it becomes easier for you
          to maintain your home in between future cleaning appointments.
        </p>
      </div>
    </div>
    <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-6">
        <div class="heading">
          <h5>All areas</h5>
        </div>
        <ul>
          <li>Clean baseboards</li>
          <li>Dust window sills</li>
          <li>Dust window blinds</li>
          <li>Wipe window blinds</li>
          <li>Dust light fixtures</li>
          <li>Dust ceiling fans</li>
          <li>Dust vents</li>
          <li>Dust lamps</li>
          <li>Dust lamp shades</li>
          <li>Dust furniture within reach (front, top, underneath)</li>
          <li>Dust picture frames</li>
          <li>Clean mirrors</li>
          <li>Clean surfaces</li>
          <li>Clean light switches</li>
          <li>Disinfect switches</li>
          <li>Clean doorknobs</li>
          <li>Disinfect doorknobs</li>
          <li>Walls spot cleaned</li>
          <li>Doors detailed</li>
          <li>Door frame detailed</li>
          <li>Clean cabinet exteriors only</li>
          <li>Clean drawer exteriors only</li>
          <li>Empty trash and replace liner</li>
          <li>Clean floors (vacuum, sweep, and mop)</li>
          <li>Remove cobwebs Kitchen</li>
        </ul>
      </div>

      <div class="col-md-1"></div>
      <div class="col-md-5">
        <img
          class="im-1 mt-5"
          src="../assets/Photos/deep-1.jpg"
          alt=""
        />
      </div>
    </div>

    <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-5">
        <img
          class="im-1 mt-5"
          src="../assets/Photos/kitchen.jpg"
          alt=""
        />
      </div>

      <div class="col-md-1"></div>
      <div class="col-md-6">
        <div class="heading">
          <h5>Kitchen</h5>
        </div>
        <ul>
          <li>Heavy scrub of stovetop to get rid of grease stains and build up</li>
          <li>Heavy scub of overhead to get rid of grease stains and build up</li>
          <li>Clean+disinfect+polish sink</li>
          <li>Clean+disinfect+polish faucet</li>
          <li>Clean+disinfect countertops</li>
          <li>Clean+disinfect backsplash</li>
          <li>Clean refrigerator exterior only (interior per request and upcharge)</li>
          <li>Clean oven exterior only (interior per request and upcharge)</li>
          <li>Clean microwave exterior only (interior per request and upcharge)</li>
          <li>Clean table and chairs</li>
        </ul>
      </div>
    </div>

    <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-6">
        <div class="heading">
          <h5>Bathroom</h5>
        </div>
        <ul>
          <li>Heavy scrub of bathtub, shower door, and shower floor</li>
          <li>Disinfect bathtub, shower door, and shower floor</li>
          <li>Heavy scrub of shower tiles</li>
          <li>Disinfect shower tiles</li>
          <li>Heavy scrub of toilet (inside, outside, base, & behind)</li>
          <li>Disinfect toilet (inside, outside, base, & behind)</li>
          <li>Clean+disinfect+polish sink</li>
          <li>Clean+disinfect+polish faucet</li>
          <li>Clean exterior of vanities</li>
          <li>Fold and hang towels neatly</li>
        </ul>
      </div>

      <div class="col-md-1"></div>
      <div class="col-md-5">
        <img
          class="im-1 mt-5"
          src="../assets/Photos/bathroom.jpg"
          alt=""
        />
      </div>
    </div>

    <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-5">
        <img
          class="im-1 mt-5"
          src="../assets/Photos/bedroom.jpg"
          alt=""
        />
      </div>

      <div class="col-md-1"></div>
      <div class="col-md-6">
        <div class="heading">
          <h5>Bedroom</h5>
        </div>
        <ul>
          <li>Make beds (linens changed upon request, must be left out on bed)</li>
        </ul>
      </div>
     
    </div>
       <button class="btn book-button mt-2"  type="button"><a href="https://cleaninfluencedmv.client-booking.com/index.php/home/start" class="book-button">Book a Cleaning</a></button>
   

    <!-- <div class="">
      <div class="row">
        <div class="col-sm-3">
          <div class="heading">
            <h5>All areas</h5>
          </div>
          <ul>
            <li>Clean baseboards</li>
            <li>Dust window sills</li>
            <li>Dust window blinds</li>
            <li>Wipe window blinds</li>
            <li>Dust light fixtures</li>
            <li>Dust ceiling fans</li>
            <li>Dust vents</li>
            <li>Dust lamps</li>
            <li>Dust lamp shades</li>
            <li>Dust furniture within reach (front, top, underneath)</li>
            <li>Dust picture frames</li>
            <li>Clean mirrors</li>
            <li>Clean surfaces</li>
            <li>Clean light switches</li>
            <li>Disinfect switches</li>
            <li>Clean doorknobs</li>
            <li>Disinfect doorknobs</li>
            <li>Walls spot cleaned</li>
            <li>Doors detailed</li>
            <li>Door frame detailed</li>
            <li>Clean cabinet exteriors only</li>
            <li>Clean drawer exteriors only</li>
            <li>Empty trash and replace liner</li>
            <li>Clean floors (vacuum, sweep, and mop)</li>
            <li>Remove cobwebs Kitchen</li>
          </ul>
        </div>
        <div class="col-sm-3">
          <div class="heading">
            <h5>Kitchen</h5>
          </div>
          <ul>
            <li>
              Heavy scrub of stovetop to get rid of grease stains and build up
            </li>
            <li>
              Heavy scub of overhead to get rid of grease stains and build up
            </li>
            <li>Clean+disinfect+polish sink</li>
            <li>Clean+disinfect+polish faucet</li>
            <li>Clean+disinfect countertops</li>
            <li>Clean+disinfect backsplash</li>
            <li>
              Clean refrigerator exterior only (interior per request and
              upcharge)
            </li>
            <li>
              Clean oven exterior only (interior per request and upcharge)
            </li>
            <li>
              Clean microwave exterior only (interior per request and upcharge)
            </li>
            <li>Clean table and chairs</li>
          </ul>
        </div>
        <div class="col-sm-3">
          <div class="heading">
            <h5>Bathroom</h5>
          </div>
          <ul>
            <li>Heavy scrub of bathtub, shower door, and shower floor</li>
            <li>Disinfect bathtub, shower door, and shower floor</li>
            <li>Heavy scrub of shower tiles</li>
            <li>Disinfect shower tiles</li>
            <li>Heavy scrub of toilet (inside, outside, base, & behind)</li>
            <li>Disinfect toilet (inside, outside, base, & behind)</li>
            <li>Clean+disinfect+polish sink</li>
            <li>Clean+disinfect+polish faucet</li>
            <li>Clean exterior of vanities</li>
            <li>Fold and hang towels neatly</li>
          </ul>
        </div>
        <div class="col-sm-3">
          <div class="heading">
            <h5>Bedroom</h5>
          </div>
          <ul>
            <li>
              Make beds (linens changed upon request, must be left out on bed)
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  path: "deepClean",
};
</script>
<style>
.left-div li,
h5,
h4,
p {
  text-align: left;
}
.heading:hover {
  border-bottom: 2px solid #198754;
}
.heading h5 {
  font-weight: 600;
  text-align: center;
  padding: 20px;
}
.heading h5:hover {
  color: #198754;
}
ul {
  padding: 0 20px;
}
ul li {
  list-style-type: disc;
}
li::marker {
  color: black;
  font-size: 20px;
}
/* .im-1 {
  width: 100%;
  height: 50vh;
  border-radius: 10px;
} */
.book-button{
  background-color:#E6F0D5;
  color:#000000;
  text-decoration:none;
}
.book-button:hover{
  color:black;
}
</style>

import firebase from 'firebase/app';
import 'firebase/firestore';
const firebaseConfig = {
    // apiKey: "AIzaSyCxhSauxatNbSx20f5MIyu1x_nVQ3OpQ0o",
    // authDomain: "clean-influence.firebaseapp.com",
    // projectId: "clean-influence",
    // storageBucket: "clean-influence.appspot.com",
    // messagingSenderId: "918314862014",
    // appId: "1:918314862014:web:77786a97b387c37fdb25ef",
    // measurementId: "G-GKD1C01PLG"
    apiKey: "AIzaSyACmIxuZtTq4O4L82poF4rfzKPvEspXFBY",
    authDomain: "cleaninfluencesite.firebaseapp.com",
    projectId: "cleaninfluencesite",
    storageBucket: "cleaninfluencesite.appspot.com",
    messagingSenderId: "725110750020",
    appId: "1:725110750020:web:17addb69a737f756a43511"
}
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();
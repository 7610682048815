<template>
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            class="navbar-nav mx-auto mb-2 mb-lg-0 d-flex justify-content-center nav-ul-li w-100"
          >
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <!-- <li class="nav-item">
                  <a class="nav-link" href="#review">Home</a>
                </li> -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul
                class="dropdown-menu"
                style="width: 17rem"
                aria-labelledby="navbarDropdown"
              >
                <router-link to="/aboutUS" class="nav-link"
                  >Welcome</router-link
                >
                <router-link to="/OurProfessionalCleaners" class="nav-link"
                  >Our Professional Cleaners and Careers</router-link
                >
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/deepClean" class="dropdown-item"
                  >Deep Clean</router-link
                >
                <router-link to="/StandardClean" class="dropdown-item"
                  >Standard Clean</router-link
                >
                <router-link to="/MoveOut" class="dropdown-item"
                  >Move Out and Move in Clean</router-link
                >
                <router-link to="/VacationRentalClean" class="dropdown-item"
                  >Vacation Rental Clean (Airbnb,Vrbo,etc.)</router-link
                >
                <router-link to="/VirusandJerms" class="dropdown-item"
                  >Virus and Germs Disinfection + Sanitization</router-link
                >
                <router-link to="/servicenotoffered" class="dropdown-item"
                  >Services Not Offered</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#review">Reviews</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client Resources
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/faqs" class="dropdown-item p-2"
                  >FAQs</router-link
                >

                <router-link to="/checkoutListing" class="dropdown-item p-2"
                  >Cleaning Checklists</router-link
                >
                <router-link to="/privacy-policy" class="dropdown-item p-2"
                  >Privacy Policy</router-link
                >
                <router-link to="/terms" class="dropdown-item p-2"
                  >Terms of Service</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link"
                >Contact Us</router-link
              >
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/client/home/login"
              >
                <button class="btn login-col" type="button">Login</button>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/home/start"
              >
                <button class="btn book-col" type="button">Book</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <h1 class="text-center mt-5">Frequently Asked Questions</h1>
    <br />
    <br />
    <div class="row flex-center">
      <div class="col-sm-10 offset-sm-2">
        <h5 class="mt-4">General Information</h5>
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span>Does Clean-Influence DMV service my area?</span>
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                Our service areas include Washington D.C., Maryland, and most
                areas in Northern Virginia. If your home is not in any of these
                areas, feel free to contact us at info@cleaninfluencedmv.com or
                240-507-5377 and we can aim to find a solution if possible.
                Kindly note that we reserve the right not to service any area
                for any reason.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span> How do I redeem my discount code or gift card? </span>
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                Simply enter your discount code or gift card code into the
                booking form as you are creating your booking. Your final price
                will automatically change to reflect your coupon balance. At any
                time you can log into your account and see the balance on any
                gift cards you have.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span>Do you offer gift cards?</span>
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                Absolutely! Go to our gift card page, fill out the form and pay
                online. Give the gift of a clean home in less than 60 seconds!
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingfour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsefour"
                aria-expanded="false"
                aria-controls="collapsefour"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span> Can I apply a coupon to an appointment? </span>
              </button>
            </h2>
            <div
              id="collapsefour"
              class="accordion-collapse collapse"
              aria-labelledby="headingfour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                Of course you can! You can apply a coupon to a future
                appointment by logging into your account and clicking your name
                in the top right hand corner. You can then select “redeem
                voucher” and enter your code. The system will automatically
                apply the code to your next appointment. You can not apply your
                coupon to past appointments that are already completed. If you
                encounter any difficulties, contact us to sort it out for you.
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h5>Booking</h5>
            <h2 class="accordion-header" id="headingfive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsefive"
                aria-expanded="false"
                aria-controls="collapsefive"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span> How do I book my first appointment? </span>
              </button>
            </h2>
            <div
              id="collapsefive"
              class="accordion-collapse collapse"
              aria-labelledby="headingfive"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                It’s simple! All you have to do is complete our secure booking
                form then select the time and date that you want us to clean
                your home. A debit or credit card must be entered to charge the
                $50 deposit and hold your appointment. It’s as simple as that
                and will only take you 60 seconds. Once you have completed your
                booking you will receive a confirmation email instantly.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingsix">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsesix"
                aria-expanded="false"
                aria-controls="collapsesix"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span>
                  Do I need a debit or credit card to make a booking?
                </span>
              </button>
            </h2>
            <div
              id="collapsesix"
              class="accordion-collapse collapse"
              aria-labelledby="headingsix"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                Yes. You need your payment card information to make an
                appointment. Your card will be charged $50 to secure your
                appointment. We value and respect each client’s time. We expect
                our clients to do the same. We only accept credit and debit
                cards as forms of payment. A hold will be placed on your account
                (for authorization) one (1) day before service in the amount of
                your remaining balance. THIS HOLD IS NOT A CHARGE. Payment is
                processed after the cleaning is completed and you will receive
                an invoice via email once this occurs.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingseven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseseven"
                aria-expanded="false"
                aria-controls="collapseseven"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span>
                  Is my billing and payment information safe and secure?</span
                >
              </button>
            </h2>
            <div
              id="collapseseven"
              class="accordion-collapse collapse"
              aria-labelledby="headingseven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                Absolutely. All billing is handled by Stripe. You can read about
                their security measures here (hyperlink) Security at Stripe |
                Stripe Documentation.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingeight">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseeight"
                aria-expanded="false"
                aria-controls="collapseeight"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span> What if I need to reschedule or cancel a booking?</span>
              </button>
            </h2>
            <div
              id="collapseeight"
              class="accordion-collapse collapse"
              aria-labelledby="headingeight"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                No problem. You may cancel or reschedule your professional
                cleaning service up to 24 hours before your appointment to have
                the $50 deposit returned to you. If your service is cancelled
                for any reason, this charge may take 2-8 business days to
                reflect in your bank transactions. We require a written request
                at least two weeks prior to your next recurring appointment if
                you wish to cancel any recurring professional cleaning services
                completely. If you cancel within less than 24 hours, or are a
                “no show” for your appointment, you will forfeit the $50
                deposit. We appreciate your understanding and cooperation with
                us in regard to this policy. It enables us to provide the best
                possible service to our clients in a timely manner.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingnine">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsenine"
                aria-expanded="false"
                aria-controls="collapsenine"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span> Can you accommodate last minute bookings?</span>
              </button>
            </h2>
            <div
              id="collapsenine"
              class="accordion-collapse collapse"
              aria-labelledby="headingnine"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                Indeed we can. Give us a call at 240-507-5377 and we will try to
                get you all set for your same day booking. For same day
                bookings, an additional $25 fee will be assessed.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingten">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseten"
                aria-expanded="false"
                aria-controls="collapseten"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span> Can you clean when I’m not home? </span>
              </button>
            </h2>
            <div
              id="collapseten"
              class="accordion-collapse collapse"
              aria-labelledby="headingten"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                Of course we can. Just leave a key for us and let us know how to
                access your place. We will clean and lock the door after
                ourselves.
              </div>
            </div>
          </div>
          <!-- <div class="accordion-item">
            <h2 class="accordion-header" id="headingeleven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseeleven"
                aria-expanded="false"
                aria-controls="collapseeleven"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span> Can you clean when I’m not home? </span>
              </button>
            </h2>
            <div
              id="collapseeleven"
              class="accordion-collapse collapse"
              aria-labelledby="headingeleven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                Of course we can. Just leave a key for us and let us know how to access
                your place. We will clean and lock the door after ourselves.
              </div>
            </div>
          </div> -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingtwelve">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsetwelve"
                aria-expanded="false"
                aria-controls="collapsetwelve"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span> Do you clean on weekends? </span>
              </button>
            </h2>
            <div
              id="collapsetwelve"
              class="accordion-collapse collapse"
              aria-labelledby="headingtwelve"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                Only on Saturdays. Sunday we are closed.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingthirteen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsethirteen"
                aria-expanded="false"
                aria-controls="collapsethirteen"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span> What services are not covered? </span>
              </button>
            </h2>
            <div
              id="collapsethirteen"
              class="accordion-collapse collapse"
              aria-labelledby="headingthirteen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                To protect the safety of our professional cleaners, we cannot
                perform the following: cleaning that requires climbing on
                ladders, exterior windows, mold removal, deep stain removal,
                insect and pest removal, cleaning pet messes, cleaning blinds,
                and items that require heavy lifting. If there is any other
                service you desire that is not listed; you can leave additional
                notes in your account after checkout, by contacting us, or by
                providing instructions during your cleaning service and we will
                see how we can possibly accommodate your request.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h5>Manage your Account</h5>
            <h2 class="accordion-header" id="headingfourteen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsefourteen"
                aria-expanded="false"
                aria-controls="collapsefourteen"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span>How do I register? </span>
              </button>
            </h2>
            <div
              id="collapsefourteen"
              class="accordion-collapse collapse"
              aria-labelledby="headingfourteen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                You can register at the time of your first booking. After a
                successful booking you will receive a confirmation email with a
                link to create a password and login to your Clean Influence -
                DMV account.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h5>Trust & Safety</h5>
            <h2 class="accordion-header" id="headingfiftteen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsefiftteen"
                aria-expanded="false"
                aria-controls="collapsefiftteen"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span>Can I trust my cleaning professional? </span>
              </button>
            </h2>
            <div
              id="collapsefiftteen"
              class="accordion-collapse collapse"
              aria-labelledby="headingfiftteen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                Most certainly! We take every precaution to hire bondable and
                vetted professional cleaners. Each and every cleaning
                professional goes through a thorough extensive background check
                prior to ever stepping foot in a home.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingsixteen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsesixteen"
                aria-expanded="false"
                aria-controls="collapsesixteen"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span
                  >Will I get the same professional cleaner(s) at every
                  appointment?
                </span>
              </button>
            </h2>
            <div
              id="collapsesixteen"
              class="accordion-collapse collapse"
              aria-labelledby="headingsixteen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                Clean Influence - DMV tries our very best but this is not a
                guarantee. We aim to please and understand that for reasons of
                comfort and convenience the best option is to ensure that the
                same teams are paired with the same clients. However, due to
                complex appointment scheduling for clients over all the DMV, as
                well as factors like vacations, family events and other factors
                that affect cleaners’ availability, we might not always be able
                to schedule you the same team every time. <br /><br />It is
                possible to always have your favorite team, if you:<br />
                <ul>
                  <li>are very flexible on your cleaning times</li>
                  <br />
                  <li>
                    inform us how important it is for you to have your team
                  </li>
                  <br />
                  <li>treat your team with kindness and respect</li>
                </ul>

                treat your team with kindness and respect Even without any
                special arrangements, most clients get their regular team 80% of
                the time. You may discover – as many of our clients do – that
                you love a new house cleaning team even more than your prior
                team! Also, we back our work with a 100% satisfaction guarantee.
                If you tried another team and weren't satisfied, we will address
                any issues.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h5>Pricing & Policies</h5>
            <h2 class="accordion-header" id="headingseventeen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseseventeen"
                aria-expanded="false"
                aria-controls="collapseseventeen"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span>What is your refund policy? </span>
              </button>
            </h2>
            <div
              id="collapseseventeen"
              class="accordion-collapse collapse"
              aria-labelledby="headingseventeen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                Clean Influence - DMV does not offer refunds. If you are unhappy
                with any aspect of your cleaning service, let us know within 24
                hours of the appointment and we will return to make things
                right. In most cases our professional cleaners will come back
                and fix any reasonable requests that were overlooked. Please
                note that a “reasonable request” is up to Clean Influence -
                DMV’s management team.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingeighteen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseeighteen"
                aria-expanded="false"
                aria-controls="collapseeighteen"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span> What is your 100% satisfaction guarantee policy? </span>
              </button>
            </h2>
            <div
              id="collapseeighteen"
              class="accordion-collapse collapse"
              aria-labelledby="headingeighteen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                We guarantee that our professional cleaners will leave your home
                spotless. If for whatever reason you are not satisfied, please
                let us know within 24 hours and we promise to make it right by
                offering a free second clean within a 7 day period.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h5>Appointments</h5>
            <h2 class="accordion-header" id="headingnineteen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsenineteen"
                aria-expanded="false"
                aria-controls="collapsenineteen"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span> How many professional cleaners will be sent? </span>
              </button>
            </h2>
            <div
              id="collapsenineteen"
              class="accordion-collapse collapse"
              aria-labelledby="headingnineteen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                We send the sufficient number of people to fulfil a service. For
                example, if it’s a standard clean for a 2-bedroom apartment, one
                or two professionals may be sufficient. If it’s a 4,000 square
                foot mansion requiring a move-in service, we would send 3-4
                people professionals.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingtwenty">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsetwenty"
                aria-expanded="false"
                aria-controls="collapsetwenty"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span> Why the 2 hour window of arrival?</span>
              </button>
            </h2>
            <div
              id="collapsetwenty"
              class="accordion-collapse collapse"
              aria-labelledby="headingtwenty"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                We always do our best to show up on time, and we do 90% of the
                time, however we ask that you please allow us a 2 hour window to
                account for GTA traffic, parking, and other surprises your
                professional cleaner(s) may encounter along the way. If there
                will be a delay longer than 30 minutes our agents will call to
                notify you.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingtwentyone">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsetwentyone"
                aria-expanded="false"
                aria-controls="collapsetwentyone"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span>
                  Does Clean Influence - DMV bring their own cleaning supplies
                  or do I need to provide them?</span
                >
              </button>
            </h2>
            <div
              id="collapsetwentyone"
              class="accordion-collapse collapse"
              aria-labelledby="headingtwentyone"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                All of our professionals arrive with their own supplies. If,
                however, you would prefer your cleaning professionals to use a
                specific product, leave it out for them or let them know once
                they arrive and they’ll be happy to accommodate your needs. Do
                note that sometimes the eco cleaning solutions are not strong
                enough to remove the discolorations, bad cases of mold or even
                white out the bottom of the shower stall, for example, versus
                simply cleaning and disinfecting it with green solutions. If you
                have a specific solution in mind, or a preference towards eco or
                non-eco solutions, please do let us know when you book your
                appointment. Your wish is our command!
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingtwentytwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsetwentytwo"
                aria-expanded="false"
                aria-controls="collapsetwentytwo"
              >
                <div class="circle-icon"><i class="fa fa-question"></i></div>
                <span>
                  What happens if something goes wrong during my
                  appointment?</span
                >
              </button>
            </h2>
            <div
              id="collapsetwentytwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingtwentytwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" style="text-align: left">
                If any incident occurs during your appointment, please don’t
                hesitate to contact us immediately at info@cleaninfluencedmv.com
                or 240-507-5377.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  path: "faqs",
  component: {},
};
</script>

<style scoped>
.flex-center {
  align-items: center;
}
.accordion-button {
  margin-bottom: 10px;
}
.accordion-body {
  margin-top: 15px;
  padding: 25px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 25px -3px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.circle-icon {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6f0d5;
  border: 5px solid #e6f0d5;
  color: #fff;
  margin-left: -20px;
  margin-right: 10px;
  transform: scale(1.2);
}
.accordion-item {
  border: 0px !important;
}
.accordion-button:not(.collapsed) {
  border: 0px !important;
  color: #b69c35;
  background-color: #ffffff;
  box-shadow: inset 0 0px 0 rgb(0 0 0 / 13%);
}
</style>

<template>
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            class="navbar-nav mx-auto mb-2 mb-lg-0 d-flex justify-content-center nav-ul-li w-100"
          >
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <!-- <li class="nav-item">
                  <a class="nav-link" href="#review">Home</a>
                </li> -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul
                class="dropdown-menu"
                style="width: 17rem"
                aria-labelledby="navbarDropdown"
              >
                <router-link to="/aboutUS" class="nav-link"
                  >Welcome</router-link
                >
                <router-link to="/OurProfessionalCleaners" class="nav-link"
                  >Our Professional Cleaners and Careers</router-link
                >
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/deepClean" class="dropdown-item"
                  >Deep Clean</router-link
                >
                <router-link to="/StandardClean" class="dropdown-item"
                  >Standard Clean</router-link
                >
                <router-link to="/MoveOut" class="dropdown-item"
                  >Move Out and Move in Clean</router-link
                >
                <router-link to="/VacationRentalClean" class="dropdown-item"
                  >Vacation Rental Clean (Airbnb,Vrbo,etc.)</router-link
                >
                <router-link to="/VirusandJerms" class="dropdown-item"
                  >Virus and Germs Disinfection + Sanitization</router-link
                >
                <router-link to="/servicenotoffered" class="dropdown-item"
                  >Services Not Offered</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#review">Reviews</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client Resources
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/faqs" class="dropdown-item p-2"
                  >FAQs</router-link
                >

                <router-link to="/checkoutListing" class="dropdown-item p-2"
                  >Cleaning Checklists</router-link
                >
                <router-link to="/privacy-policy" class="dropdown-item p-2"
                  >Privacy Policy</router-link
                >
                <router-link to="/terms" class="dropdown-item p-2"
                  >Terms of Service</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link"
                >Contact Us</router-link
              >
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/client/home/login"
              >
                <button class="btn login-col" type="button">Login</button>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/home/start"
              >
                <button class="btn book-col" type="button">Book</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="row justify-content-center">
      <div class="col-xs-12 text-center">
        <h1 class="mt-4">Terms of Services</h1>
      </div>

      <div class="col-xs-12 text-start">
        <div class="content">
          <p>
            Whether you are a new or existing client, we are elated to service
            you! We value all our client relationships and understand how much
            of a privilege it is to be entrusted to work in your home. Our
            service agreement is presented below. It is the foundation of our
            relationship with you. Please take the time to read it carefully in
            its entirety, as it constitutes a legal agreement between you and
            Clean Influence - DMV.
          </p>

          <h5 class="section-title">Bonding and Insurance</h5>
          <p>
            All Clean Influence - DMV employees are bonded with ? and insured
            with Hiscox. All employees have also passed screenings for drugs and
            extensive criminal background checks.
          </p>
          <h5 class="section-title">Payment and Booking</h5>

          <p>
            Clean Influence - DMV outlines all pricing information prior to any
            professional cleaning service(s). During the booking process you
            will be asked to provide your payment details and your card will be
            charged $50 to secure your appointment. We value and respect each
            client’s time. We expect our clients to do the same. We only accept
            credit and debit cards as forms of payment. A hold will be placed on
            your account (for authorization) one (1) day before service in the
            amount of your remaining balance. THIS HOLD IS NOT A CHARGE. Payment
            is processed after the cleaning is completed and you will receive an
            invoice via email once this occurs. Unless otherwise noted, payment
            information must be collected from all clients and authorized prior
            to starting a service.
          </p>
          <h5 class="section-title">Cancellations</h5>
          <p>
            You may cancel or reschedule your professional cleaning service up
            to 24 hours before your appointment to have the $50 deposit returned
            to you. If your service is cancelled for any reason, this charge may
            take 2-8 business days to reflect in your bank transactions. We
            require a written request at least two weeks prior to your next
            recurring appointment if you wish to cancel any recurring
            professional cleaning services completely.
          </p>
          <h5 class="section-title">Refunds</h5>
          <p>
            Clean Influence - DMV will not offer refunds. If you are not
            satisfied with our service, we are not satisfied. After services
            have been rendered, we ask you to perform the mandatory walk through
            inspection with the team of cleaning professionals on site. As a
            guideline, please use our cleaning checklist (hyperlink) during the
            walk through. If you are not 100% satisfied with the service
            performed, we are more than willing and happy to re-clean any missed
            areas per your request, free of charge at the time of service. We
            urge you to please take your time and inspect carefully. After the
            walk through is completed, our professional services are considered
            fulfilled and any additional service(s) will be charged accordingly.
            In an event where clients are unable to perform the walk through due
            to not being home, not available or simply choosing not to perform
            the walk through inspection, our satisfaction guarantee will be
            considered null and void.
          </p>
          <h5 class="section-title">
            How to Prepare for Our Professional Cleaning Services
          </h5>
          <p>
            Our cleaning professionals will make your bed (unless otherwise
            requested). Light organizing and straightening up in bedrooms are
            included (picking up items that are in our way). We recommend a Deep
            Clean (hyperlink) for the first visit in order to get your home
            prepared for ongoing maintenance. Laundry should not be in the way
            and dishes should be removed from the sink prior to our arrival. We
            empty all waste baskets and take out any trash when we leave.
          </p>
          <h5 class="section-title">Safety Policy</h5>
          <p>
            Reaching High Areas – We cannot guarantee we can reach areas higher
            than 6′ ft. Due to liability and safety reasons, our cleaning
            professionals are not able to climb on ladders or reach high areas.
            Moving Large Appliances – We are happy to clean around or underneath
            your large appliances, however due to liability reasons, our
            cleaning professionals are not able to move them. If you would like
            us to clean beneath or behind large appliances, we kindly ask that
            you please move large appliances for us. Interior Window Cleaning –
            Windows should be accessible using a 2-3 step ladder. Due to
            liability and safety reasons, our cleaning professionals are not
            able to climb on large ladders or reach high areas. Screen Cleaning
            – We request that the customer remove screens for our cleaning
            professionals.. Due to liability reasons they are not able to detach
            and/or install window screens.
          </p>
          <h5 class="section-title">Health Precautions</h5>
          <p>
            Any obvious body fluids from both humans and animals will be
            completely avoided. Areas with pest infestations including mice will
            be avoided. If unsanitary conditions or items are encountered, we
            reserve the right to immediately vacate the premises and request
            full payment.
          </p>
          <h5 class="section-title">Products and Equipment</h5>
          <p>
            Clean Influence - DMV will provide all cleaning products and
            equipment (solutions, rags, brooms, mops, vacuums, etc.) unless
            otherwise requested. However, we do request each home provide a
            toilet brush for sanitary reasons as we do not carry these in
            between households. We dust and clean all hard surfaces and items on
            them, but we do not clean electronics such as televisions and
            computer screens.
          </p>
          <h5 class="section-title">Cleaning Products</h5>
          <p>
            Clean Influence - DMV strives to always use eco-conscious practices
            and non-toxic products because it's safe for us and the environment.
            Our preferred cleaning products do contain chlorine bleach, ammonia,
            or formaldehyde. Natural cleaning products still provide effective
            cleaning, stain-lifting, deodorizing, and disinfecting without the
            dangers of using harsh chemicals. However, kindly note that for very
            difficult areas to clean, we may have to use commercial grade
            cleaning products. All commercial grade cleaning products are not
            natural or eco-friendly. If you do not want us to use these, please
            let us know.
          </p>
          <h5 class="section-title">Pictures</h5>
          <p>
            Clean Influence - DMV will take before and after pictures at every
            visit for any of our professional services. The pictures will be
            attached to client files for insurance purposes. We may use some
            pictures on our social media platforms and website for promotional
            reasons. We will always ask for your permission prior to doing so.
            Clean Influence - DMV reserves the right to modify or update the
            terms and conditions of the service agreement at any time and
            without notice. If our terms and conditions change in the future we
            will keep you informed through this website. If you have any
            questions or suggestions regarding our service agreement, please
            contact us via email at info@cleaninfluencedmv.com or by phone at
            240-507-5377. A member from our dedicated team will respond within
            24 to 48 business hours.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  path: "terms",
  component: {},
};
</script>

<style scoped>
.section-title {
  color: black;
}
</style>

<template>
  <div class="container">
   <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            class="navbar-nav mx-auto mb-2 mb-lg-0 d-flex justify-content-center nav-ul-li w-100"
          >
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <!-- <li class="nav-item">
                  <a class="nav-link" href="#review">Home</a>
                </li> -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul
                class="dropdown-menu"
                style="width: 17rem"
                aria-labelledby="navbarDropdown"
              >
                <router-link to="/aboutUS" class="nav-link"
                  >Welcome</router-link
                >
                <router-link to="/OurProfessionalCleaners" class="nav-link"
                  >Our Professional Cleaners and Careers</router-link
                >
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/deepClean" class="dropdown-item"
                  >Deep Clean</router-link
                >
                <router-link to="/StandardClean" class="dropdown-item"
                  >Standard Clean</router-link
                >
                <router-link to="/MoveOut" class="dropdown-item"
                  >Move Out and Move in Clean</router-link
                >
                <router-link to="/VacationRentalClean" class="dropdown-item"
                  >Vacation Rental Clean (Airbnb,Vrbo,etc.)</router-link
                >
                <router-link to="/VirusandJerms" class="dropdown-item"
                  >Virus and Germs Disinfection + Sanitization</router-link
                >
                <router-link to="/servicenotoffered" class="dropdown-item"
                  >Services Not Offered</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#review">Reviews</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client Resources
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/faqs" class="dropdown-item p-2"
                  >FAQs</router-link
                >

                <router-link to="/checkoutListing" class="dropdown-item p-2"
                  >Cleaning Checklists</router-link
                >
                <router-link to="/privacy-policy" class="dropdown-item p-2"
                  >Privacy Policy</router-link
                >
                <router-link to="/terms" class="dropdown-item p-2"
                  >Terms of Service</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link"
                >Contact Us</router-link
              >
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/client/home/login"
              >
                <button class="btn login-col" type="button">Login</button>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/home/start"
              >
                <button class="btn book-col" type="button">Book</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div style="text-align: center">
      <h2 class="mt-4">Contact Us</h2>
    </div>
    <div class="row">
      <div class="col-md-6 size">
        <img
          src="../assets/images/Blog_cleaning_1.jpg"
          style="border-radius: 20px"
        />
      </div>
      <div class="col-md-6 mt-5">
        <form @submit.prevent="onFormSubmit">
          <div class="mb-3" style="text-align: left">
            <label for="fname" class="form-label">First Name</label>
            <input
              type="text"
              id="fname"
              name="firstname"
              v-model="user.firstname"
              class="form-control"
              placeholder="Your name.."
              required
              v-on:keypress="isLetter($event)"
            />
            <!-- <p
              v-if="user.firstname === '' && user.firstname.length == 1"
              style="color: red"
            >
              <small class="text-danger">first name is invalid</small>
            </p> -->
          </div>
          <div class="mb-3" style="text-align: left">
            <label for="lname" class="form-label">Last Name</label>
            <input
              type="text"
              id="lname"
              name="lastname"
              class="form-control"
              v-model="user.lastname"
              placeholder="Your last name.."
              required
              v-on:keypress="isLetter($event)"
            />
            <!-- <p
              v-if="user.lastname.length <= 2 && user.lastname.length > 0"
              style="color: red"
            >
              <small class="text-danger">last name is invalid</small>
            </p> -->
          </div>
          <!-- <div class="mb-3" style="text-align: left">
            <label for="country" class="form-label">Country</label>
            <select
              id="country"
              name="country"
              class="form-control"
              v-model="user.country"
             required>
              <option value="australia">Australia</option>
              <option value="canada">Canada</option>
              <option value="usa">USA</option>
            </select>
          </div> -->
          <div class="mb-3" style="text-align: left">
            <!-- <label for="subject" class="form-label">Subject</label> -->
            <textarea
              id="subject"
              name="subject"
              placeholder="Write something.."
              v-model="user.subject"
              class="form-control"
              style="height: 170px"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            class="btn submit btn-lg"
            style="border-radius: 30px"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../firebase";
export default {
  path: "contactUS",
  // component: {},
  data() {
    return {
      user: {
        firstname: "",
        lastname: "",
        country: "",
        subject: "",
      },
    };
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    onFormSubmit() {
      db.collection("usersss")
        .add(this.user)
        .then(() => {
          alert("contact details Added !");
          this.user.firstname = "";
          this.user.lastname = "";
          this.user.country = "";
          this.user.subject = "";
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    firstNameValid() {
      return /^[A-Za-z]+$/.test(this.firstName);
    },
  },
};
</script>

<style>
body {
  font-family: Arial, Helvetica, sans-serif;
}

* {
  box-sizing: border-box;
}

/* Style inputs */

input[type="submit"] {
  background-color: #04aa6d;
  color: white;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

/* Style the container/contact section */
.container {
  border-radius: 5px;

  padding: 10px;
}

/* Create two columns that float next to eachother */

.column img {
  border-radius: 20px;
}
.submit{
  background-color:#e6f0d5;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.size {
  margin-top: 100px;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}
</style>

<template>

<div class="container">
 <section>
      <div class="row">
        <h2 class="section-heading mb-5">Our Services</h2>
      </div>
      <div class="row">
        <div class="column">
        <router-link to="/deepClean" class="text-decoration-none">
          <div class="card">
            <div class="icon-wrapper">
            <!-- <i class="fa-solid fa-house"></i> -->
              <!-- <i class="fas fa-house"></i> -->
              <img src="../assets/images/Clean.png" alt="">
            </div>
            <h3>
            Deep Clean</h3>
            <p class="deep">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam
              consequatur necessitatibus eaque.
            </p>
          </div>
          </router-link>
        </div>
        <div class="column">
         <router-link to="/StandardClean" class="text-decoration-none">
          <div class="card">
            <div class="icon-wrapper">
             <img src="../assets/images/Clean.png" alt="">
            </div>
            <h3>
           Standard Clean</h3>
            <p class="deep">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam
              consequatur necessitatibus eaque.
            </p>
          </div>
          </router-link>
        </div>
        <div class="column">
        <router-link to="/MoveOut" class="text-decoration-none">
          <div class="card">
            <div class="icon-wrapper">
               <img src="../assets/images/Clean.png" alt="">
            </div>
            <h3>
            Move Out and Move In Clean</h3>
            <p class="deep">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam
              consequatur necessitatibus eaque.
            </p>
          </div>
          </router-link>
        </div>
        <div class="column">
        <router-link to="/VacationRentalClean" class="text-decoration-none">
          <div class="card">
            <div class="icon-wrapper">
               <img src="../assets/images/Clean.png" alt="">
            </div>
            <h3>
            Vacation Rental Clean (Airbnb, Vrbo, etc.)</h3>
            <p>
            <p class="deep">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam
              consequatur necessitatibus eaque.
            </p>
          </div>
          </router-link>
        </div>
        <div class="column">
        <router-link to="/VirusandJerms" class="text-decoration-none">
          <div class="card">
            <div class="icon-wrapper">
               <img src="../assets/images/Clean.png" alt="">
            </div>
             <h3>
            Virus and Germs Disinfection+Sanitization</h3>
            <p>
            <p class="deep"> 
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam
              consequatur necessitatibus eaque.
            </p>
          </div>
          </router-link>
        </div>
        
        <!-- <div class="column">
          <div class="card">
            <div class="icon-wrapper">
              <img src="../assets/images/bathroom-icon.png" alt="">
            </div>
            <h3>Bathroom Cleaning</h3>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam
              consequatur necessitatibus eaque.
            </p>
          </div>
        </div> -->
      </div>
    </section>


</div>
</template>

<script>
export default{
 path: "services",
 component: {}

}
</script>

<style>

.row {
  display: flex;
  flex-wrap: wrap;
}
.column {
  width: 100%;
  padding: 0 1em 1em 1em;
  text-align: center;
}
.card {
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  background: linear-gradient(#ffffff 50%, #2c7bfe 50%);
  background-size: 100% 200%;
  background-position: 0 2.5%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
}
h3 {
  font-size: 20px;
  font-weight: 600;
  color: #1f194c;
  margin: 1em 0;
}
p {
  color: #575a7b;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
}
.icon-wrapper {
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
}
.card:hover {
  background-position: 0 100%;
}
.card:hover .icon-wrapper {
  background-color: #ffffff;
  color: #2c7bfe;
}
/* .card:hover.deep{
  color:#fff;
} */
  .card:hover p {
    color: #ffffff !important;
  }
.card:hover h3 {
  color: #ffffff;
}




.deep:hover{
  color:white !important;
}




@media screen and (min-width: 768px) {
  section {
    padding: 0 2em;
  }
  .column {
    flex: 0 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 992px) {
  section {
    padding: 1em 3em;
  }
  .column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

</style>
<template>
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            class="navbar-nav mx-auto mb-2 mb-lg-0 d-flex justify-content-center nav-ul-li w-100"
          >
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <!-- <li class="nav-item">
                  <a class="nav-link" href="#review">Home</a>
                </li> -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul
                class="dropdown-menu"
                style="width: 17rem"
                aria-labelledby="navbarDropdown"
              >
                <router-link to="/aboutUS" class="nav-link"
                  >Welcome</router-link
                >
                <router-link to="/OurProfessionalCleaners" class="nav-link"
                  >Our Professional Cleaners and Careers</router-link
                >
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/deepClean" class="dropdown-item"
                  >Deep Clean</router-link
                >
                <router-link to="/StandardClean" class="dropdown-item"
                  >Standard Clean</router-link
                >
                <router-link to="/MoveOut" class="dropdown-item"
                  >Move Out and Move in Clean</router-link
                >
                <router-link to="/VacationRentalClean" class="dropdown-item"
                  >Vacation Rental Clean (Airbnb,Vrbo,etc.)</router-link
                >
                <router-link to="/VirusandJerms" class="dropdown-item"
                  >Virus and Germs Disinfection + Sanitization</router-link
                >
                <router-link to="/servicenotoffered" class="dropdown-item"
                  >Services Not Offered</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#review">Reviews</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client Resources
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/faqs" class="dropdown-item p-2"
                  >FAQs</router-link
                >

                <router-link to="/checkoutListing" class="dropdown-item p-2"
                  >Cleaning Checklists</router-link
                >
                <router-link to="/privacy-policy" class="dropdown-item p-2"
                  >Privacy Policy</router-link
                >
                <router-link to="/terms" class="dropdown-item p-2"
                  >Terms of Service</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link"
                >Contact Us</router-link
              >
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/client/home/login"
              >
                <button class="btn login-col" type="button">Login</button>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/home/start"
              >
                <button class="btn book-col" type="button">Book</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-5">
        <img class="im-1 mt-5" src="../assets/Photos/moveout-1.jpg" alt="" />
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-6">
        <h5 class="mt-3" style="font-size: 22px; font-weight:600;text-align:left;">
          Why hire our professional cleaners for a move out or move clean?
        </h5>
        <p>
          Is there anything more exciting than moving into a new home? You get to choose
          your new decor, paint colors, or maybe some new furniture – the possibilities
          can seem endless! <br />
          <br />But also…is there anything more stressful? You have to pack your items,
          arrange for them to arrive at your new home, and then unpack everything. You may
          even be moving to a completely new city and starting a new job in the middle of
          this, or dealing with enrolling your children into a new school. You will most
          likely need to set up services for utilities and close old ones. If that’s not
          overwhelming enough, you STILL have to clean your old home to perfection for its
          new occupants AND your new home to be able to settle in nicely and feel
          comfortable.<br /><br />
          That’s right! In the midst of everything else, you will have to clean! But, do
          not fret. Our professional cleaners can take this burden off your shoulders to
          enable you to focus on all the other important aspects of your move.<br /><br />
          No one wants to move into a dirty home. Once you leave a home, it still needs to
          be cleaned and prepared for its next occupants, and that means getting it as
          good as new. If you’ve been renting from a landlord, this is even more
          important. To get your security deposit back; you will have to return the keys
          to a sparkling clean and undamaged home that is ready for the next occupants.
          Before moving into a new home, it will have to be habitable and up to your
          standards for hygienic and health purposes. The purpose of our move out and move
          in services is to address all the aforementioned concerns.
        </p>
       
       
      </div>
    </div>


 <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-5 mt-5">
       <h5 style="font-size: 22px; font-weight:600;text-align:left;">What is a move out and move in clean?</h5>
    <p>
      Getting a home back to looking brand-new takes even more time and effort than a
      normal clean because of the dust, dirt, and grime that accumulates after years of
      living in the same place. Once you move the furniture out of its spot, you’ll see
      what we mean.<br /><br />
      A move in clean is an essential part of the moving process because it ensures that
      your new home is ready to be lived in. It will ease the settling in experience
      because you will be able to move into a clean and healthy home as you start a new
      chapter.<br /><br />
      It is assumed that your home will be clear of all boxes and furniture when our
      cleaning professionals arrive for the move out or move in clean. If this is not the
      case, it will affect the time needed to complete the cleaning service and therefore
      the cost. But don’t worry, our pricing is affordable and we will communicate this to
      you for your agreement before we start to clean. Please remember not to turn off
      your power or water utilities until after we have cleaned your home. The
      equipment(s) we will use require the use of those services.
    </p>
       
      </div>

      <div class="col-md-1"></div>
      <div class="col-md-6">
       <img class="im-1 mt-5" src="../assets/Photos/moveout-2.jpg" alt="" />
     
       
       
      </div>
    </div>

    <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-6">
      <img class="im-1 mt-5" src="../assets/Photos/moveout-4.jpg" alt="" />
       
      </div>

      <div class="col-md-1"></div>
      <div class="col-md-5">
       <div class="heading">
          <h5>All areas</h5>
        </div>
       <ul>
          <li>Clean baseboards</li>
          <li>Dust window sills</li>
          <li>Dust window blinds</li>
          <li>Wipe window blinds</li>
          <li>Dust light fixtures</li>
          <li>Dust ceiling fans</li>
          <li>Dust vents</li>
          <li>Clean mirrors</li>
          <li>Clean surfaces</li>
          <li>Disinfect surfaces</li>
          <li>Clean cabinets (interior & exterior)</li>
          <li>Clean drawers (interior & exterior)</li>
          <li>Clean floors (vacuum, sweep, & mop)</li>
          <li>Remove cobwebs</li>
        </ul>
        
      </div>
    </div>

    <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-5">
      <div class="heading">
          <h5>Kitchen</h5>
        </div>
         <ul>
          <li>Heavy scrub of stovetop to get rid of grease stains and build up</li>
          <li>Heavy scub of overhead to get rid of grease stains and build up</li>
          <li>Clean+disinfect+polish sink</li>
          <li>Clean+disinfect+polish faucet</li>
          <li>Clean+disinfect countertops</li>
          <li>Clean+disinfect backsplash</li>
          <li>Clean refrigerator (interior & exterior)</li>
          <li>Clean oven (interior & exterior)</li>
          <li>Clean microwave (interior & exterior)</li>
        </ul>
      </div>

      <div class="col-md-1"></div>
      <div class="col-md-6">
      
       <img class="im-1 mt-5" src="../assets/Photos/moveout-3.jpg" alt="" />
       
      
       
       
      </div>
    </div>

    <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-6">
       <img class="im-1 mt-5" src="../assets/Photos/bathroom.jpg" alt="" />
       
      </div>

      <div class="col-md-1"></div>
      <div class="col-md-5">
       <div class="heading">
          <h5>Bathroom</h5>
        </div>
        <ul>
          <li>Heavy scrub of tub, shower door, and shower floor</li>
          <li>Disinfect tub, shower door, and shower floor</li>
          <li>Heavy scrub of shower tiles</li>
          <li>Disinfect shower tiles</li>
          <li>Heavy scrub of toilet (inside, outside, base, & behind)</li>
          <li>Disinfect toilet (inside, outside, base, & behind)</li>
          <li>Clean+disinfect+polish sink</li>
          <li>Clean+disinfect+polish faucets</li>
          <li>Clean vanities (interior & exterior)</li>
        </ul>
       
      </div>
    </div>
     <button class="btn book-button mt-2"  type="button"><a href="https://cleaninfluencedmv.client-booking.com/index.php/home/start" class="book-button">Book a Cleaning</a></button>

    <!-- <div class="row d-flex"> -->
      <!-- <div class="col-md-5">
        <img class="im-1 mt-5" src="../assets/Photos/bedroom.jpg" alt="" />
      </div>

      <div class="col-md-1"></div> -->
      <!-- <div class="col-md-6">
        <div class="heading">
          <h5>Bedroom</h5>
        </div>
        <ul>
          <li>Make beds (linens changed upon request, must be left out on bed)</li>
        </ul>
      </div> -->
    <!-- </div> -->

    <!-- <h1 class="mt-4">Move Out and Move In Clean</h1>
    <div class="d-flex justify-content-between">
      <img class="im-1" src="../assets/Photos/img-3.jpg" alt="" />
    </div>
    <h4 class="mt-5">
      When you’re in the middle of coordinating all the different aspects of your big
      move, you simply don’t have the time or the energy to get your old home and new home
      ready!
    </h4>
    <h5 class="mt-5">Why hire our professional cleaners for a move out or move clean?</h5>
    <p>
      Is there anything more exciting than moving into a new home? You get to choose your
      new decor, paint colors, or maybe some new furniture – the possibilities can seem
      endless! <br />
      <br />But also…is there anything more stressful? You have to pack your items,
      arrange for them to arrive at your new home, and then unpack everything. You may
      even be moving to a completely new city and starting a new job in the middle of
      this, or dealing with enrolling your children into a new school. You will most
      likely need to set up services for utilities and close old ones. If that’s not
      overwhelming enough, you STILL have to clean your old home to perfection for its new
      occupants AND your new home to be able to settle in nicely and feel comfortable.<br /><br />
      That’s right! In the midst of everything else, you will have to clean! But, do not
      fret. Our professional cleaners can take this burden off your shoulders to enable
      you to focus on all the other important aspects of your move.<br /><br />
      No one wants to move into a dirty home. Once you leave a home, it still needs to be
      cleaned and prepared for its next occupants, and that means getting it as good as
      new. If you’ve been renting from a landlord, this is even more important. To get
      your security deposit back; you will have to return the keys to a sparkling clean
      and undamaged home that is ready for the next occupants. Before moving into a new
      home, it will have to be habitable and up to your standards for hygienic and health
      purposes. The purpose of our move out and move in services is to address all the
      aforementioned concerns.
    </p>
    <h5>What is a move out and move in clean?</h5>
    <p>
      Getting a home back to looking brand-new takes even more time and effort than a
      normal clean because of the dust, dirt, and grime that accumulates after years of
      living in the same place. Once you move the furniture out of its spot, you’ll see
      what we mean.<br /><br />
      A move in clean is an essential part of the moving process because it ensures that
      your new home is ready to be lived in. It will ease the settling in experience
      because you will be able to move into a clean and healthy home as you start a new
      chapter.<br /><br />
      It is assumed that your home will be clear of all boxes and furniture when our
      cleaning professionals arrive for the move out or move in clean. If this is not the
      case, it will affect the time needed to complete the cleaning service and therefore
      the cost. But don’t worry, our pricing is affordable and we will communicate this to
      you for your agreement before we start to clean. Please remember not to turn off
      your power or water utilities until after we have cleaned your home. The
      equipment(s) we will use require the use of those services.
    </p>
    <h5>A move out and move in clean includes the following:</h5>
    <div class="row">
      <div class="col-sm-3">
        <div class="heading">
          <h5>All areas</h5>
        </div>
        <ul>
          <li>Clean baseboards</li>
          <li>Dust window sills</li>
          <li>Dust window blinds</li>
          <li>Wipe window blinds</li>
          <li>Dust light fixtures</li>
          <li>Dust ceiling fans</li>
          <li>Dust vents</li>
          <li>Clean mirrors</li>
          <li>Clean surfaces</li>
          <li>Disinfect surfaces</li>
          <li>Clean cabinets (interior & exterior)</li>
          <li>Clean drawers (interior & exterior)</li>
          <li>Clean floors (vacuum, sweep, & mop)</li>
          <li>Remove cobwebs</li>
        </ul>
      </div>

      <div class="col-sm-3">
        <div class="heading">
          <h5>Kitchen</h5>
        </div>
        <ul>
          <li>Heavy scrub of stovetop to get rid of grease stains and build up</li>
          <li>Heavy scub of overhead to get rid of grease stains and build up</li>
          <li>Clean+disinfect+polish sink</li>
          <li>Clean+disinfect+polish faucet</li>
          <li>Clean+disinfect countertops</li>
          <li>Clean+disinfect backsplash</li>
          <li>Clean refrigerator (interior & exterior)</li>
          <li>Clean oven (interior & exterior)</li>
          <li>Clean microwave (interior & exterior)</li>
        </ul>
      </div>

      <div class="col-sm-3">
        <div class="heading">
          <h5>Bathrooms</h5>
        </div>
        <ul>
          <li>Heavy scrub of tub, shower door, and shower floor</li>
          <li>Disinfect tub, shower door, and shower floor</li>
          <li>Heavy scrub of shower tiles</li>
          <li>Disinfect shower tiles</li>
          <li>Heavy scrub of toilet (inside, outside, base, & behind)</li>
          <li>Disinfect toilet (inside, outside, base, & behind)</li>
          <li>Clean+disinfect+polish sink</li>
          <li>Clean+disinfect+polish faucets</li>
          <li>Clean vanities (interior & exterior)</li>
        </ul>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  path: "MoveOut",
};
</script>
<style>
div li,
h5,
p {
  text-align: left;
}
</style>

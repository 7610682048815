<template>
  <div class="container">
   <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            class="navbar-nav mx-auto mb-2 mb-lg-0 d-flex justify-content-center nav-ul-li w-100"
          >
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <!-- <li class="nav-item">
                  <a class="nav-link" href="#review">Home</a>
                </li> -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul
                class="dropdown-menu"
                style="width: 17rem"
                aria-labelledby="navbarDropdown"
              >
                <router-link to="/aboutUS" class="nav-link"
                  >Welcome</router-link
                >
                <router-link to="/OurProfessionalCleaners" class="nav-link"
                  >Our Professional Cleaners and Careers</router-link
                >
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/deepClean" class="dropdown-item"
                  >Deep Clean</router-link
                >
                <router-link to="/StandardClean" class="dropdown-item"
                  >Standard Clean</router-link
                >
                <router-link to="/MoveOut" class="dropdown-item"
                  >Move Out and Move in Clean</router-link
                >
                <router-link to="/VacationRentalClean" class="dropdown-item"
                  >Vacation Rental Clean (Airbnb,Vrbo,etc.)</router-link
                >
                <router-link to="/VirusandJerms" class="dropdown-item"
                  >Virus and Germs Disinfection + Sanitization</router-link
                >
                <router-link to="/servicenotoffered" class="dropdown-item"
                  >Services Not Offered</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#review">Reviews</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client Resources
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/faqs" class="dropdown-item p-2"
                  >FAQs</router-link
                >

                <router-link to="/checkoutListing" class="dropdown-item p-2"
                  >Cleaning Checklists</router-link
                >
                <router-link to="/privacy-policy" class="dropdown-item p-2"
                  >Privacy Policy</router-link
                >
                <router-link to="/terms" class="dropdown-item p-2"
                  >Terms of Service</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link"
                >Contact Us</router-link
              >
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/client/home/login"
              >
                <button class="btn login-col" type="button">Login</button>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/home/start"
              >
                <button class="btn book-col" type="button">Book</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    
   
    <!-- <div class="row mt-5">
      <div class="col-4">
       
      </div>

      <div class="col-8">
        <img src="../assets/images/clean.jpg" class="img-fluid img-22" alt="" />
      </div>
    </div> -->

    <div class="row">
      <div class="col-md-5">
      <img class="im-1 image-margin " src="../assets/Photos/about-1.jpg" alt="" />
      
      </div>
       <div class="col-md-1"></div>

      <div class="col-md-6">
        <h2 class="txt-align mt-5 text-center ">CLEAN INFLUENCE-DMV</h2>
        <hr />
        <p class="mt-3">
          Clean Influence – DMV is a professional cleaning company that presently only
          serves the Washington metropolitan area.
        </p>
        <p>
          We are not your average cleaning company.Clean Influence - DMV was founded based
          on the core belief that the cleanliness and organization of your environment,
          whether it is your home or workspace, has a direct influence on you as an
          individual in terms of your productivity, mood, emotional state, stress level,
          mental clarity, and health.
        </p>
        <p>
          It is this belief that makes us dedicated to providing each client an
          exceptional cleaning experience. We maintain our reputation for excellence and
          customer satisfaction by never losing sight of our core vision and mission.
        </p>
        <p>Learn more about how these core values set us apart.</p>
        <h4 class="txt-align colr">Our Vision</h4>
        <p>
          Clean Influence - DMV will be the dominant professional cleaning service
          provider in our markets.
        </p>
        <p>
          In order to reach this goal, we created our cleaning system (hyperlink). This
          system has been perfected carefully and is employed during every visit. We use
          the cleaning system in conjunction with the Clean Influence Pristine Checklist
          (hyperlink), to ensure that each of our clients receive the same top tier
          service.
        </p>
        <h4 class="txt-align colr">Our Mission</h4>
        <p>
          Clean Influence - DMV aspires to have an impactful and positive influence on the
          quality of our clients’ lives and the people we employ. Our mission is to
          positively impact the quality of life for:
        </p>
        <ul class="mission">
          <li>
            <strong> Clients we serve</strong> by consistently providing professional
            cleaning services that are thorough and systematic.
          </li>
          <li>
            <strong> People we employ</strong> by providing stable jobs with fair wages,
            and a work-life balance.
          </li>
          <li>
            <strong> Future generations</strong> by using eco-friendly cleaning products
            and practices.
          </li>
        </ul>
        <p>
          Future generations by using eco-friendly cleaning products and practices. You
          have better things to do! We can help you save time which empowers you to focus
          on the things and people you love that matter most to you. Make cleaning our
          responsibility. Trust us.
        </p>
        </div>
         
        <div class="row mt-5">
        <div class="col-md-6">
        <p class="txt-3">Why choose Clean Influence – DMV?</p>
        <p>
          - We are ISSA (hyperlink) certified. ISSA is the leading trade association for
          the cleaning industry worldwide. Their certification means we have reached
          standards that recognize us for having higher quality services and practices, a
          stronger service reputation, and a more proactive cleaning response than our
          competitors.
        </p>
        <p>
          - We have more than 28 years combined experience in the cleaning industry. Our
          knowledge and expertise is reflected in our loyal clients’ satisfaction and our
          very own cleaning system (hyperlink) to guarantee the same quality of service
          every visit.
        </p>
        <p>
          - Each of our cleaning professionals are highly skilled experts. They must take
          a course, pass, and earn a Professional House Cleaning (PHC) Certification from
          ARCSI before joining our team and serving our clients.
        </p>
        <p>
          - Our cleaning professionals are vetted extensively. They must pass drug tests
          and criminal background checks.
        </p>
        <p>- We are bonded and insured.</p>
        <p>
          - Client satisfaction is our driving force and for your convenience, we have
          employed a secured and encrypted cashless payment system and a mobile app to
          make managing your appointments easier than ever.
        </p>
        <p>
          - We offer more value. Compared to our competitors; the services we offer
          include more. Our services are comprehensive because we are meticulous in the
          manner that we clean.
        </p>
        <p>- We are eco-conscious and will not harmfully impact the environment.</p>
      


        </div>
        <div class="col-md-1"></div>
      
        <div class="col-md-5">
         <img class=" " src="../assets/Photos/abou-2.jpg" alt="" />


        </div>
        </div>




        
    </div>
  </div>
</template>

<!-- <div class="row">
    <div class="col-4">
      <a :href="`mailto: sadia@cleaninfluence.com`">
        <h3><i class="fa fa-envelope"> </i> sadia@cleaninfluence.com</h3>
      </a>
    </div>
    <div class="col-4">
      <i class="fa fa-phone"></i><br />
      Phone<br />
      240-636-4400
    </div>
    <div class="col-4">
      Follow us <br />
      <i class="fab fa-instagram"></i>
      www.instagram.com<br />
      <i class="fab fa-twitter"></i>
      www.twiter.com
    </div>
  </div> -->
<!-- <div class="container ">
  <h2>About Us</h2>
  <div class="contact content">
    <form class="form-contain">
      <div class="form-group" style="text-align: left;margin-top: 20px;">
        <label for="">Irean Shumpert</label>
        <input
          type=""
          class="form-control"
          id=""
          aria-describedby=""
          placeholder="Your Answer"
        />
      </div>
      <div class="form-group" style="text-align: left;margin-top: 20px;">
        <label for="exampleInputEmail1">Email address</label>
        <input
          type="email"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Your Answer"
        />
      </div>
      <div class="form-group" style="text-align: left;margin-top: 20px;">
        <label for="">2029075562</label>
        <input
          type=""
          class="form-control"
          id=""
          aria-describedby=""
          placeholder="Your Answer"
        />
      </div>



          <div class="text-start my-5">
          <label for="">How many year of  paid cleaning experience do you have*</label>
      
      <div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
    Less then 1 year
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    1 Year
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    2 Year
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    3 Year
  </label>
</div>
</div>








    <div class="text-start my-5">
       <label for="">Are you available for Part-Time or Full-Time work? *</label>
      
       <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
  <label class="form-check-label" for="flexCheckDefault">
  Sunday
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
  <label class="form-check-label" for="flexCheckChecked">
    Monday
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
  <label class="form-check-label" for="flexCheckChecked">
    Tuesday
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
  <label class="form-check-label" for="flexCheckChecked">
    Wednesday
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
  <label class="form-check-label" for="flexCheckChecked">
   Thursday
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
  <label class="form-check-label" for="flexCheckChecked">
    Friday
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
  <label class="form-check-label" for="flexCheckChecked">
   Saturday
  </label>
</div>



 <div class="text-start my-5">
          <label for="">Preferrd days to work*</label>
      
     
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    Yes
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    No
  </label>
</div>

</div>



<div class="text-start my-5">
          <label for="">Do you have a vehicle and a driver's licence*</label>
      
     
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    Yes
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    No
  </label>
</div>

</div>


<div class="text-start my-5">
          <label for="">Do you have own cleaning supplies*</label>
      
     
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    Yes
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    No
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    I can get them
  </label>
</div>

</div>




<div class="text-start my-5">
          <label for="">are you willing to undergo a criminal abckground check*</label>
      
     
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    Yes
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    No
  </label>
</div>


</div>



<div class="text-start my-5">
          <label for="">Are you legally authrorized to work in the U.S.*</label>
      
     
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    Yes
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
  <label class="form-check-label" for="flexRadioDefault2">
    No
  </label>
</div>


</div>




      <div class="form-group" style="text-align: left;margin-top: 20px;">
        <label for="">Additional comments</label>
        <input
          type=""
          class="form-control"
          id=""
          aria-describedby=""
          placeholder="Your Answer"
        />
      </div>
    </div>
      <div class="form-group form-check" style="text-align: left;margin-top: 20px;">
        <input type="checkbox" class="form-check-input" id="exampleCheck1" />
        <label class="form-check-label" for="exampleCheck1">Check me out</label>
      </div>
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>
  </div> -->

<script>
export default {
  name: "aboutUS",
  props: {
    msg: String,
  },
  components: {},

  mounted() {},
  computed: {
    options: function () {
      var services = {};

      this.services.forEach((s) => {
        console.log(s);
        services[s.name] = `${s.name} - ${s.cost}`;
      });

      return services;
    },
  },
  methods: {
    book() {
      this.$modal.show("book");
    },
    onBookOpen() {
      this.card.mount("#card-element");
    },
    join() {
      this.$modal.show("join");
    },
    async submit() {
      if (!this.checkout.length)
        return window.alert("You must select at least one service");

      const r = await this.stripe.createToken(this.card);
      if (r.error) return window.alert(r.error.message);

      var services = [];
      this.checkout.forEach((c) => {
        services.push(this.options[c]);
      });

      // Hide modal
      this.$modal.hide("book");

      try {
        // Submit

        // Success
        window.alert(
          "Congratulations! You have just completed a booking with Clean Influence. You will be contacted soon"
        );
      } catch {
        // Error
        window.alert("Booking could not be completed");
      }
    },
  },
  data: () => {
    return {
      introText: "",
      title: "",
      subtitle: "",
      job: "",
      faqs: [],
      stats: [],
      checkout: {},
      quote: {},
      headlines: [],
      formValues: {},
      values: [],
      reviews: [],
      steps: [],
      services: [],
    };
  },
};
</script>
<style>
.background-image {
  background-image: url(../assets/images/clean.jpg);
}

.nav-link .icon {
  display: none;
}
.nav-text:hover + .icon {
  display: block;
}
.form-contain {
  max-width: 35rem;
  margin: auto;
}
.txt-align {
  text-align: left;
}
.colr {
  color:#43545E;
  font-size: 20px;
}
p {
  color: #000000 !important;
}
.txt1 {
  font-size: 10px;
}
.mission li {
  list-style-type: disc !important;
}
.mission li::marker {
  color: #F4EFE9;
  font-size: 30px;
}
.btn:focus {
  box-shadow: none;
}
.mrgin {
  margin-top: 60%;
  font-size: 57px;
}
.txt-3 {
  color: black !important;
  font-size:20px;
  font-weight:600;
}
.vision {
  text-align: left;
  margin-top: 30%;
}
.img-22 {
  border-radius: 10px;
}
li {
  /* display: inline-block */
  margin: 0 10px;
}
.vision-style {
  background-color: darkmagenta;
  color: white;
  padding: 10%;
  width: 101%;
  z-index: 100;
  position: relative;
  left: 15%;
}
.book-col {
  background-color: black;
  color: white;
  font-weight: 600;
}
.book-col:hover {
  color: white;
}
.login-col {
  color: black;
  font-size: 16px;
  font-weight: 600;
}
.nav-ul-li li {
  list-style-type: none;
}
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}
.image-margin{
  margin-top:35%;
}
</style>

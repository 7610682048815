import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import Landing from "./components/Landing";
import contactUS from "./components/contactUS";
import signUplogin from "./components/signUplogin";
import services from "./components/services";
import aboutUS from "./components/aboutUS";
import Faqs from "./components/Faqs";
import TermsAndService from "./components/TermsAndService";
import checkoutListing from "./components/checkoutListing";
import deepClean from "./components/deepClean";
import StandardClean from "./components/StandardClean";
import MoveOut from "./components/MoveOut";
import VacationRentalClean from "./components/VacationRentalClean";
import VirusandJerms from "./components/VirusandJerms";
import privacyPolicy from './components/privacyPolicy';
import ServiceNotOffered from './components/ServiceNotOffered';
import OurProfessionalCleaners from './components/OurProfessionalCleaners';
Vue.config.productionTip = false;
Vue.use(VueRouter);
const routes = [
  { path: "/", component: Landing },
  { path: "/contact", component: contactUS },
  { path: "/privacy-policy", component: privacyPolicy },
  { path: "/signup", component: signUplogin },
  { path: "/services", component: services },
  { path: "/aboutUS", component: aboutUS },
  { path: "/faqs", component: Faqs },
  { path: "/terms", component: TermsAndService },
  { path: "/checkoutListing", component: checkoutListing },
  { path: "/deepClean", component: deepClean },
  { path: "/StandardClean", component: StandardClean },
  { path: "/MoveOut", component: MoveOut },
  { path: "/VacationRentalClean", component: VacationRentalClean },
  { path: "/VirusandJerms", component: VirusandJerms },
  { path: "/ServiceNotOffered", component: ServiceNotOffered },
  { path: "/ServiceNotOffered", component: ServiceNotOffered },
  { path: "/OurProfessionalCleaners", component: OurProfessionalCleaners },
];
const router = new VueRouter({
  routes,
});
Vue.config.productionTip = false;

new Vue({
  router: router,
  render: (h) => h(App),
}).$mount("#app");

<template>
      <div class="container mt-5">
      <h1 class="text-center">SignUp</h1>
   <form >
          <div class="mb-3 div-contain">
             <p for="text" class="form-label text-start">Name</p>
      <input type="text" class="form-control " required />
         </div>
    <div class="mb-3 div-contain">
      <p for="exampleInputEmail1" class="form-label text-start">Email address</p>
      <input
        type="email"
        class="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
      />
        </div>
    <div class="mb-3 div-contain">
      <p for="exampleInputPassword1" class="form-label text-start">Password</p>
      <input type="password" class="form-control text-start" id="exampleInputPassword1" />
    </div>
    <div class="mb-3 div-contain">
      <p for="exampleInputPassword1" class="form-label text-start">Confirm Password</p>
      <input type="password" class="form-control" id="exampleInputPassword1" />
    </div>
       <button type="submit" class="btn btn-primary">Submit</button>
  </form>
   <form >
      <h1 class="text-center mt-5">Login</h1>
             <div class="mb-3 div-contain">
      <p for="exampleInputEmail1" class="form-label text-start">Email address</p>
      <input
        type="email"
        class="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
      />
        </div>
    <div class="mb-3 div-contain">
      <p for="exampleInputPassword1" class="form-label text-start">Password</p>
      <input type="password" class="form-control text-start" id="exampleInputPassword1" />
    </div>
          <button type="submit" class="btn btn-primary">Login</button>
  </form>
 </div>
</template>
<script>
export default {
  path: "signUplogin",
  component: {},
};
</script>

<style scoped>
.div-contain{
max-width:35rem;
margin:auto;
}
</style>

<template>
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            class="navbar-nav mx-auto mb-2 mb-lg-0 d-flex justify-content-center nav-ul-li w-100"
          >
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <!-- <li class="nav-item">
                  <a class="nav-link" href="#review">Home</a>
                </li> -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul
                class="dropdown-menu"
                style="width: 17rem"
                aria-labelledby="navbarDropdown"
              >
                <router-link to="/aboutUS" class="nav-link"
                  >Welcome</router-link
                >
                <router-link to="/OurProfessionalCleaners" class="nav-link"
                  >Our Professional Cleaners and Careers</router-link
                >
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/deepClean" class="dropdown-item"
                  >Deep Clean</router-link
                >
                <router-link to="/StandardClean" class="dropdown-item"
                  >Standard Clean</router-link
                >
                <router-link to="/MoveOut" class="dropdown-item"
                  >Move Out and Move in Clean</router-link
                >
                <router-link to="/VacationRentalClean" class="dropdown-item"
                  >Vacation Rental Clean (Airbnb,Vrbo,etc.)</router-link
                >
                <router-link to="/VirusandJerms" class="dropdown-item"
                  >Virus and Germs Disinfection + Sanitization</router-link
                >
                <router-link to="/servicenotoffered" class="dropdown-item"
                  >Services Not Offered</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#review">Reviews</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client Resources
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/faqs" class="dropdown-item p-2"
                  >FAQs</router-link
                >

                <router-link to="/checkoutListing" class="dropdown-item p-2"
                  >Cleaning Checklists</router-link
                >
                <router-link to="/privacy-policy" class="dropdown-item p-2"
                  >Privacy Policy</router-link
                >
                <router-link to="/terms" class="dropdown-item p-2"
                  >Terms of Service</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link"
                >Contact Us</router-link
              >
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/client/home/login"
              >
                <button class="btn login-col" type="button">Login</button>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/home/start"
              >
                <button class="btn book-col" type="button">Book</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="row d-flex" style="background-color: #F4EFE9">
      <div class="col-md-5">
        <img class="im-1 mt-5" src="../assets/Photos/vacation-1.jpg" alt="" />
      </div>

      <div class="col-md-1"></div>
      <div class="col-md-6">
        <h4 class="mt-5">
          Even with the most exquisite property that offers all the amenities someone
          could dream of, your guests will not be happy or satisfied if it is not clean.
          To earn that ever-important five star glowing review, your guests must feel that
          they are safe and healthy in a clean, hygienic, well-maintained space.
        </h4>
        <p>
          Owning a rental business is one of the most accessible forms of earning passive
          income. However, managing and maintaining these properties is not easy. As a
          landlord, you are continuously bombarded with a list of responsibilities that
          require your attention so allow Clean Influence - DMV to alleviate your stress
          by taking the rental cleaning off your list. Our dedicated team of cleaning
          professionals will ensure that your rental property is always in pristine
          condition and ready for your influx of renters. We assure you that your rental
          is in safe hands.
        </p>
        <p>
          It is of the utmost importance to clean your entire rental space after your
          guest(s) check-out and before your new guest(s) check-in to avoid any
          inconveniences. Many of the negative complaints on vacation rental booking sites
          involve issues with the spaces being improperly cleaned or cleaning not
          occurring at all between guests. When you have a high influx of guests, cleaning
          sometimes can get challenging, and your rental’s upkeep may suffer. To prevent
          such scenarios and loss of potential guests (which is also potential loss of
          revenue) hire our team of cleaning professionals that you can trust to to
          service your rental and maintain a well cleaned and hygienic pleasant
          environment continuously
        </p>
      </div>
    </div>

    <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-5 mt-5">
        <p>
           <span class="head-1" style="font-size: 22px; font-weight:600;text-align:left;"> Convenient </span>  <br />What's more convenient than having a trusted professional
          cleaning company like Clean Influence - DMV by your side to service all your
          cleaning needs? You simply have to book our professionals to clean your rental
          space, and it will be in tip-top shape perpetually.
        </p>
        <p>
             <span class="head-1" style="font-size: 22px; font-weight:600;text-align:left;"> Save Time  </span> <br />You may find that there simply is not enough time to manage a
          rental property’s upkeep while trying to juggle its other responsibilities and
          your own everyday life. With the possibility of one set of guests checking out
          and another checking in within hours, you simply do not have time to waste on a
          half-hearted cleaning job. You need a reliable professional cleaning company
          that can get in and out within a short time period. Allow Clean Influence - DMV
          to simplify the equation by giving your rental the proper attention it deserves.
        </p>
        <p>
          <span class="head-1" style="font-size: 22px; font-weight:600;text-align:left;"> Best Results </span> <br />It doesn't matter how much time, energy, and effort you put
          into cleaning a rental property yourself; all your hard work won't be comparable
          to the Clean Influence - DMV professional touch and state-of-the-art equipment.
          Let our cleaning professionals handle what we do best so you can have one less
          thing to worry about
        </p>
      </div>

      <div class="col-md-1"></div>
      <div class="col-md-6">
        <img class="im-1 mt-5" src="../assets/Photos/deep.jpg" alt="" />
      </div>
    </div>
     <button class="btn book-button mt-2"  type="button"><a href="https://cleaninfluencedmv.client-booking.com/index.php/home/start" class="book-button">Book a Cleaning</a></button>

    <!-- <h1 class="mt-4">Vacation Rental Clean (Airbnb, Vrbo, etc.)</h1>
     <div class="d-flex justify-content-between">
    <img class="im-1" src="../assets/Photos/vacation.png" alt="" >
    </div>
    <h4 class="mt-5">
      Even with the most exquisite property that offers all the amenities someone could
      dream of, your guests will not be happy or satisfied if it is not clean. To earn
      that ever-important five star glowing review, your guests must feel that they are
      safe and healthy in a clean, hygienic, well-maintained space.
    </h4>
    <p>
      Owning a rental business is one of the most accessible forms of earning passive
      income. However, managing and maintaining these properties is not easy. As a
      landlord, you are continuously bombarded with a list of responsibilities that
      require your attention so allow Clean Influence - DMV to alleviate your stress by
      taking the rental cleaning off your list. Our dedicated team of cleaning
      professionals will ensure that your rental property is always in pristine condition
      and ready for your influx of renters. We assure you that your rental is in safe
      hands.
    </p>
    <p>
      It is of the utmost importance to clean your entire rental space after your guest(s)
      check-out and before your new guest(s) check-in to avoid any inconveniences. Many of
      the negative complaints on vacation rental booking sites involve issues with the
      spaces being improperly cleaned or cleaning not occurring at all between guests.
      When you have a high influx of guests, cleaning sometimes can get challenging, and
      your rental’s upkeep may suffer. To prevent such scenarios and loss of potential
      guests (which is also potential loss of revenue) hire our team of cleaning
      professionals that you can trust to to service your rental and maintain a well
      cleaned and hygienic pleasant environment continuously
    </p>
    <p>
      Convenient<br />What's more convenient than having a trusted professional cleaning
      company like Clean Influence - DMV by your side to service all your cleaning needs?
      You simply have to book our professionals to clean your rental space, and it will be
      in tip-top shape perpetually.
    </p>
    <p>
      Save Time <br />You may find that there simply is not enough time to manage a rental
      property’s upkeep while trying to juggle its other responsibilities and your own
      everyday life. With the possibility of one set of guests checking out and another
      checking in within hours, you simply do not have time to waste on a half-hearted
      cleaning job. You need a reliable professional cleaning company that can get in and
      out within a short time period. Allow Clean Influence - DMV to simplify the equation
      by giving your rental the proper attention it deserves.
    </p>
    <p>
      Best Results <br>It doesn't matter how much time, energy, and effort you put into
      cleaning a rental property yourself; all your hard work won't be comparable to the
      Clean Influence - DMV professional touch and state-of-the-art equipment. Let our
      cleaning professionals handle what we do best so you can have one less thing to
      worry about
    </p> -->
  </div>
</template>
<script>
export default {
  path: "VacationRentalClean",
  component: {},
};
</script>
<style>
.head-1{
  font-size:22px;
}

</style>

<template>
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            class="navbar-nav mx-auto mb-2 mb-lg-0 d-flex justify-content-center nav-ul-li w-100"
          >
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <!-- <li class="nav-item">
                  <a class="nav-link" href="#review">Home</a>
                </li> -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul
                class="dropdown-menu"
                style="width: 17rem"
                aria-labelledby="navbarDropdown"
              >
                <router-link to="/aboutUS" class="nav-link"
                  >Welcome</router-link
                >
                <router-link to="/OurProfessionalCleaners" class="nav-link"
                  >Our Professional Cleaners and Careers</router-link
                >
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/deepClean" class="dropdown-item"
                  >Deep Clean</router-link
                >
                <router-link to="/StandardClean" class="dropdown-item"
                  >Standard Clean</router-link
                >
                <router-link to="/MoveOut" class="dropdown-item"
                  >Move Out and Move in Clean</router-link
                >
                <router-link to="/VacationRentalClean" class="dropdown-item"
                  >Vacation Rental Clean (Airbnb,Vrbo,etc.)</router-link
                >
                <router-link to="/VirusandJerms" class="dropdown-item"
                  >Virus and Germs Disinfection + Sanitization</router-link
                >
                <router-link to="/servicenotoffered" class="dropdown-item"
                  >Services Not Offered</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#review">Reviews</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client Resources
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/faqs" class="dropdown-item p-2"
                  >FAQs</router-link
                >

                <router-link to="/checkoutListing" class="dropdown-item p-2"
                  >Cleaning Checklists</router-link
                >
                <router-link to="/privacy-policy" class="dropdown-item p-2"
                  >Privacy Policy</router-link
                >
                <router-link to="/terms" class="dropdown-item p-2"
                  >Terms of Service</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link"
                >Contact Us</router-link
              >
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/client/home/login"
              >
                <button class="btn login-col" type="button">Login</button>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/home/start"
              >
                <button class="btn book-col" type="button">Book</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>



 <div class="row d-flex mt-5 " style="background-color: #F4EFE9">
      <div class="col-md-6">
        <div class="heading">
         
        </div>
       <h4 class="mt-5">
      The onset of COVID-19 was a state of emergency that called for joint communal
      efforts. Together as a community, we can win the battle against viruses such as
      COVID-19 and emerge more triumphant than before!
    </h4>
    <h5 class="mt-3" style="font-size: 22px; font-weight:600;text-align:left;">What is Virus & Germs Disinfection + Sanitization?</h5>
    <p>
      This is an add-on service that we highly advise all clients to schedule at least
      once every eight weeks. Our cleaning professionals will use Hydrogen Peroxide before
      cleaning any surface (two layer cleaning), which is a powerful disinfectant that
      will kill bacteria like staph and viruses such as COVID-19, H1N1, and SARS. Hydrogen
      Peroxide is a great disinfectant because it does not have a smell. It is also safe
      for all including children and pets.
    </p>
    <h5 class="mt-3" style="font-size: 22px; font-weight:600;text-align:left;">How does this differ from a deep clean or standard clean?</h5>
    <p>
      This is an add-on service only at an upcharge. Not a complete clean of your home and
      its rooms. Our cleaning professionals will mostly focus on wiping down surfaces that
      people touch on a regular basis which include: light switches, doorknobs, door
      handles, television remotes, pull cords of blinds, etc.<br />
      While booking your professional cleaning service, you can simply add this service
      for an additional fee.
    </p>
      </div>

      <div class="col-md-1"></div>
      <div class="col-md-5">
        <img class="im-1 mt-5" src="../assets/Photos/deep.jpg" alt="" />
      </div>
    </div>
    <button class="btn book-button mt-2"  type="button"><a href="https://cleaninfluencedmv.client-booking.com/index.php/home/start" class="book-button">Book a Cleaning</a></button>





    <!-- <h1 class="mt-4">Virus & Germs Disinfection+Sanitization</h1>
    <div class="d-flex justify-content-between">
      <img class="im-1" src="../assets/Photos/sanitization.jpg" alt="" />
    </div>
    <h4 class="mt-5">
      The onset of COVID-19 was a state of emergency that called for joint communal
      efforts. Together as a community, we can win the battle against viruses such as
      COVID-19 and emerge more triumphant than before!
    </h4>
    <h5 class="mt-3">What is Virus & Germs Disinfection+Sanitization?</h5>
    <p>
      This is an add-on service that we highly advise all clients to schedule at least
      once every eight weeks. Our cleaning professionals will use Hydrogen Peroxide before
      cleaning any surface (two layer cleaning), which is a powerful disinfectant that
      will kill bacteria like staph and viruses such as COVID-19, H1N1, and SARS. Hydrogen
      Peroxide is a great disinfectant because it does not have a smell. It is also safe
      for all including children and pets.
    </p>
    <h5 class="mt-3">How does this differ from a deep clean or standard clean?</h5>
    <p>
      This is an add-on service only at an upcharge. Not a complete clean of your home and
      its rooms. Our cleaning professionals will mostly focus on wiping down surfaces that
      people touch on a regular basis which include: light switches, doorknobs, door
      handles, television remotes, pull cords of blinds, etc.<br />
      While booking your professional cleaning service, you can simply add this service
      for an additional fee.
    </p> -->
  </div>
</template>

<script>
export default {
  path: "VirusandJerms",
  component: {},
};
</script>

<style></style>

<template>
  <div class="container">
   <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            class="navbar-nav mx-auto mb-2 mb-lg-0 d-flex justify-content-center nav-ul-li w-100"
          >
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <!-- <li class="nav-item">
                  <a class="nav-link" href="#review">Home</a>
                </li> -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul
                class="dropdown-menu"
                style="width: 17rem"
                aria-labelledby="navbarDropdown"
              >
                <router-link to="/aboutUS" class="nav-link"
                  >Welcome</router-link
                >
                <router-link to="/OurProfessionalCleaners" class="nav-link"
                  >Our Professional Cleaners and Careers</router-link
                >
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/deepClean" class="dropdown-item"
                  >Deep Clean</router-link
                >
                <router-link to="/StandardClean" class="dropdown-item"
                  >Standard Clean</router-link
                >
                <router-link to="/MoveOut" class="dropdown-item"
                  >Move Out and Move in Clean</router-link
                >
                <router-link to="/VacationRentalClean" class="dropdown-item"
                  >Vacation Rental Clean (Airbnb,Vrbo,etc.)</router-link
                >
                <router-link to="/VirusandJerms" class="dropdown-item"
                  >Virus and Germs Disinfection + Sanitization</router-link
                >
                <router-link to="/servicenotoffered" class="dropdown-item"
                  >Services Not Offered</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#review">Reviews</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client Resources
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/faqs" class="dropdown-item p-2"
                  >FAQs</router-link
                >

                <router-link to="/checkoutListing" class="dropdown-item p-2"
                  >Cleaning Checklists</router-link
                >
                <router-link to="/privacy-policy" class="dropdown-item p-2"
                  >Privacy Policy</router-link
                >
                <router-link to="/terms" class="dropdown-item p-2"
                  >Terms of Service</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link"
                >Contact Us</router-link
              >
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/client/home/login"
              >
                <button class="btn login-col" type="button">Login</button>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/home/start"
              >
                <button class="btn book-col" type="button">Book</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <h1 class="mt-4">Privacy Policy</h1>
    <p>
      Clean Influence - DMV is committed to protecting and respecting your privacy. This
      policy explains how any personal data we collect from you, or that you provide to
      us, will be processed. Please read the following carefully to understand our views
      and practices regarding your personal data and how we will manage it. By visiting
      the Clean Influence - DMV website, you are accepting and consenting to the practices
      described in this policy. We will only use the information that we collect about you
      lawfully.
    </p>
    <h3 class="privacy">We will collect and process the following data about you:</h3>
    <p>
      Information you give us - This is information about you that you give us by filling
      in forms on our website or by corresponding with us by phone, e-mail or otherwise.
      It includes information you provide when you register to use our site, search for
      and/or subscribe to our service and when you report a problem with our site. The
      information you give us may include your name, address, e-mail address and phone
      number, and other relevant details about your home to aid us with offering you our
      cleaning services. Information we collect about you - With regard to each of your
      visits to our website we will automatically collect the following information:
      technical information, including the (IP) address used to connect your device to the
      internet, browser type and version, time zone setting, browser plug-in types and
      versions, operating system and platform, information about your visit, including the
      full URL, clickstream to, through and from our site (including date and time),
      products and services you viewed or searched for, page response times, download
      errors, length of visits to certain pages, page interaction information (such as
      scrolling, clicks, and mouse-overs), methods used to browse away from the page, and
      any phone number used to call our client services number. Information we receive
      from other sources - We are working closely with third parties such as advertising
      networks, analytics providers, and search information providers. We will process
      data we receive from other sources in accordance with this policy.
    </p>
    <h3 class="privacy">We use information held about you in the following ways:</h3>
    <p>
      Information you give to us - We will use this information to communicate with you
      following your inquiry or booking request . We will provide the information,
      products and services that you request from us. We will also provide information
      about other products and services we offer that are like those that you have already
      received or inquired about. Information we collect about you - We will use this
      information to administer our site and for internal operations, including
      troubleshooting, data analysis, testing, research, statistical and survey purposes;
      to improve our site to ensure that content is presented in the most effective manner
      for you and for your devices; to allow you to participate in interactive features of
      our service, when you choose to do so; as part of our efforts to keep our site safe
      and secure; to measure or understand the effectiveness of advertising we serve to
      you and others, and to deliver relevant advertising to you; to make suggestions and
      recommendations to you and other users of our site about goods or services that may
      interest you or them. Information we receive from other sources - We will combine
      this information with information you give to us and information we collect about
      you. We will use this information and the combined information for the purposes set
      out above (depending on the types of information we receive).
    </p>
    <h3 class="privacy">Disclosure of your information:</h3>
    <p>
      You agree that we have the right to share your personal information with: any Clean
      Influence - DMV employee; selected third parties including: business partners,
      suppliers and subcontractors for the performance of any contract we enter into with
      them or you; advertisers and advertising networks that require the data to select
      and serve relevant adverts to you and others. We do not disclose information about
      identifiable individuals to our advertisers, but we will provide them with aggregate
      information about our users. We may make use of the personal data we have collected
      from you to enable us to comply with our advertisers’ wishes by displaying their
      advertisement to that target audience; analytics and search engine providers that
      assist us in the improvement and optimization of our site; credit reference agencies
      for the purpose of assessing your credit score where this is a condition of us
      entering into a contract with you.
    </p>
    <h3 class="privacy">
      All the information you provide is stored on our secure servers or those servers of
      select third parties.
    </h3>
    <p>
      Where we have given you (or where you have chosen) a password which enables you to
      access certain parts of our site, you are responsible for keeping this password
      confidential. We ask you not to share a password with anyone. Unfortunately, the
      transmission of information via the internet is not completely secure. Although we
      will do our best to protect your personal data, we cannot guarantee the security of
      your data transmitted to our site; any transmission is at your own risk. Once we
      have received your information, we will use strict procedures and security features
      to try to prevent unauthorized access.
    </p>
    <h3 class="privacy">Changes to our privacy policy.</h3>
    <p>
      Any future changes we make to our privacy policy in the will be posted on this page
      and, where appropriate, notified to you by e-mail. Please check back frequently to
      see any updates or changes to our privacy policy.
    </p>
  </div>
</template>
<script>
export default {
  path: "privacyPolicy",
  component: {},
};
</script>

<style scoped>
.flex-center {
  align-items: center;
}
.accordion-button {
  margin-bottom: 10px;
}
.accordion-body {
  margin-top: 15px;
  padding: 25px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 25px -3px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.circle-icon {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2b4eff;
  border: 5px solid #b2bfff;
  color: #fff;
  margin-left: -20px;
  margin-right: 10px;
  transform: scale(1.2);
}
.accordion-item {
  border: 0px !important;
}
.accordion-button:not(.collapsed) {
  border: 0px !important;
  color: #0c63e4;
  background-color: #ffffff;
  box-shadow: inset 0 0px 0 rgb(0 0 0 / 13%);
}
.privacy {
  font-size: 20px;
  font-weight: 600;
  color: black;
  margin: 1em 0;
  text-align: left;
}
</style>

<template>
  <div class="container left-div">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            class="navbar-nav mx-auto mb-2 mb-lg-0 d-flex justify-content-center nav-ul-li w-100"
          >
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <!-- <li class="nav-item">
                  <a class="nav-link" href="#review">Home</a>
                </li> -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul
                class="dropdown-menu"
                style="width: 17rem"
                aria-labelledby="navbarDropdown"
              >
                <router-link to="/aboutUS" class="nav-link"
                  >Welcome</router-link
                >
                <router-link to="/OurProfessionalCleaners" class="nav-link"
                  >Our Professional Cleaners and Careers</router-link
                >
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/deepClean" class="dropdown-item"
                  >Deep Clean</router-link
                >
                <router-link to="/StandardClean" class="dropdown-item"
                  >Standard Clean</router-link
                >
                <router-link to="/MoveOut" class="dropdown-item"
                  >Move Out and Move in Clean</router-link
                >
                <router-link to="/VacationRentalClean" class="dropdown-item"
                  >Vacation Rental Clean (Airbnb,Vrbo,etc.)</router-link
                >
                <router-link to="/VirusandJerms" class="dropdown-item"
                  >Virus and Germs Disinfection + Sanitization</router-link
                >
                <router-link to="/servicenotoffered" class="dropdown-item"
                  >Services Not Offered</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#review">Reviews</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client Resources
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/faqs" class="dropdown-item p-2"
                  >FAQs</router-link
                >

                <router-link to="/checkoutListing" class="dropdown-item p-2"
                  >Cleaning Checklists</router-link
                >
                <router-link to="/privacy-policy" class="dropdown-item p-2"
                  >Privacy Policy</router-link
                >
                <router-link to="/terms" class="dropdown-item p-2"
                  >Terms of Service</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link"
                >Contact Us</router-link
              >
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/client/home/login"
              >
                <button class="btn login-col" type="button">Login</button>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/home/start"
              >
                <button class="btn book-col" type="button">Book</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-5">
        <img class="im-1 mt-5" src="../assets/Photos/standard-clean.jpg" alt="" />
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-6">
        <h5 class="mt-3" style="font-size: 22px; font-weight:600;text-align:left;">What is standard clean?</h5>
        <p>
          Standard cleans are for new clients who recently had professional cleaning
          services from elsewhere or existing Clean Influence - DMV clients who receive
          regularly scheduled cleaning services from us either monthly, biweekly, or
          weekly. This is a maintenance clean. Your home has already been deep cleaned and
          is maintained at a reasonable level of cleanliness.
        </p>
         <h5 class="mt-5" style="font-size: 22px; font-weight:600;text-align:left;">What if a standard clean is not enough?:</h5>
        <p>
          The standard clean service includes all cleaning supplies necessary to get the
          job done- saving you time at the store and money at the checkout counter. If you
          have a preference toward eco or non-eco friendly products, please let us know
          when you schedule your first appointment, and we will work to accommodate your
          request. Please note, eco-friendly cleaning solutions are great at disinfecting
          surfaces during standard cleans, but may not be strong enough to remove strong
          discolorations or bad cases of mold. While the standard clean is generally
          enough to keep your spaces looking and smelling fresh, a deep clean (hyperlink)
          service is sometimes necessary.
        </p>
      </div>
    </div>
    <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-6">
        <div class="heading">
          <h5>All areas</h5>
        </div>
        <ul>
         
          <li>Dust window sills</li>
          <li>Dust window blinds</li>
          <li>Dust lamps</li>
           <li>Dust lamp shades</li>
          <li>Dust furniture within reach (front, top, underneath)</li>
          <li>Dust picture frames</li>
           <li>Dust light fixtures</li>
            <li>Clean mirrors</li>
             <li>Clean surfaces</li>
               <li>Disinfect switches</li>
                 <li>Clean cabinet exteriors only</li>
          <li>Clean drawer exteriors only</li>
            <li>Empty trash and replace liner</li>
          <li>Clean floors (vacuum, sweep, and mop)</li>
          <li>Remove cobwebs Kitchen</li>
         
         
          
        
        
        </ul>
      </div>

      <div class="col-md-1"></div>
      <div class="col-md-5">
        <img class="im-1 mt-5" src="../assets/Photos/standard-clean2.jpg" alt="" />
      </div>
    </div>

    <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-5">
        <img class="im-1 mt-5" src="../assets/Photos/standard-clean3.jpg" alt="" />
      </div>

      <div class="col-md-1"></div>
      <div class="col-md-6">
        <div class="heading">
          <h5>Kitchen</h5>
        </div>
        <ul>
            <li>Wipe stovetop</li>
            <li>Wipe overhead</li>
            <li>Clean+disinfect+polish sink</li>
            <li>Clean+disinfect+polish faucet</li>
            <li>Clean+disinfect countertops</li>
            <li>Clean+disinfect backsplash</li>
            <li>Clean refrigerator exterior only (interior per request and upcharge)</li>
            <li>Clean oven exterior only (interior per request and upcharge)</li>
            <li>Clean microwave exterior only (interior per request and upcharge)</li>
            <li>Clean table and chairs</li>
          </ul>
      </div>
    </div>

    <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-6">
        <div class="heading">
          <h5>Bathroom</h5>
        </div>
        <ul>
            <li>Heavy scrub of bathtub, shower door, and shower floor</li>
            <li>Disinfect bathtub, shower door, and shower floor</li>
            <li>Heavy scrub of shower tiles</li>
            <li>Disinfect shower tiles</li>
            <li>Heavy scrub of toilet (inside, outside, base, & behind)</li>
            <li>Disinfect toilet (inside, outside, base, & behind)</li>
            <li>Clean+disinfect+polish sink</li>
            <li>Clean+disinfect+polish faucet</li>
            <li>Fold and hang towels neatly</li>
          </ul>
      </div>

      <div class="col-md-1"></div>
      <div class="col-md-5">
        <img class="im-1 mt-5" src="../assets/Photos/standard-clean4.jpg" alt="" />
      </div>
    </div>
    <div class="row d-flex mt-5" style="background-color: #F4EFE9">
      <div class="col-md-5">
        <img
          class="im-1 mt-5"
          src="../assets/Photos/standard-clean5.jpg"
          alt=""
        />
      </div>

      <div class="col-md-1"></div>
      <div class="col-md-6">
        <div class="heading">
          <h5>Bedroom</h5>
        </div>
        <ul>
          <li>Make beds (linens changed upon request, must be left out on bed)</li>
        </ul>
      </div>
    </div>
    <button class="btn book-button mt-2"  type="button"><a href="https://cleaninfluencedmv.client-booking.com/index.php/home/start" class="book-button">Book a Cleaning</a></button>
   

    <!-- <h1 class="mt-4">Standard Clean</h1>
    <div class="d-flex justify-content-between">
      <img class="im-1" src="../assets/Photos/st.jpg" alt="" />
    </div>
    <h4 class="mt-5">
      Do you feel overwhelmed and frustrated at the thought of having to clean the kitchen
      sink or toilet bowl one more time? We get it, your time is valuable, your to-do list
      is full, and mopping the hallway after a long day of work is the last thing you want
      to do. Delegate the task to us. We are here to help!
    </h4>
    <h5 class="mt-3">What is standard clean?</h5>
    <p>
      Standard cleans are for new clients who recently had professional cleaning services
      from elsewhere or existing Clean Influence - DMV clients who receive regularly
      scheduled cleaning services from us either monthly, biweekly, or weekly. This is a
      maintenance clean. Your home has already been deep cleaned and is maintained at a
      reasonable level of cleanliness.
    </p>
    <h5>A standard clean includes the following:</h5>
    <div class="">
      <div class="row">
        <div class="col-sm-3">
          <div class="heading">
            <h5>All areas</h5>
          </div>
          <ul>
            <li>Dust window sills</li>
            <li>Dust window blinds</li>
            <li>Dust lamps</li>
            <li>Dust lamp shades</li>
            <li>Dust furniture within reach (top, front & underneath)</li>
            <li>Dust picture frames</li>
            <li>Dust light fixtures</li>
            <li>Clean mirrors</li>
            <li>Clean surfaces</li>
            <li>Disinfect surfaces</li>
            <li>Clean cabinet exteriors only</li>
            <li>Clean drawer exteriors only</li>
            <li>Empty trash and replace liner</li>
            <li>Clean floors (vacuum, sweep, & mop)</li>
            <li>Remove cobwebs</li>
          </ul>
        </div>
        <div class="col-sm-3">
          <div class="heading">
            <h5>Kitchen</h5>
          </div>
          <ul>
            <li>Wipe stovetop</li>
            <li>Wipe overhead</li>
            <li>Clean+disinfect+polish sink</li>
            <li>Clean+disinfect+polish faucet</li>
            <li>Clean+disinfect countertops</li>
            <li>Clean+disinfect backsplash</li>
            <li>Clean refrigerator exterior only (interior per request and upcharge)</li>
            <li>Clean oven exterior only (interior per request and upcharge)</li>
            <li>Clean microwave exterior only (interior per request and upcharge)</li>
            <li>Clean table and chairs</li>
          </ul>
        </div>
        <div class="col-sm-3">
          <div class="heading">
            <h5>Bathroom</h5>
          </div>
          <ul>
            <li>Heavy scrub of bathtub, shower door, and shower floor</li>
            <li>Disinfect bathtub, shower door, and shower floor</li>
            <li>Heavy scrub of shower tiles</li>
            <li>Disinfect shower tiles</li>
            <li>Heavy scrub of toilet (inside, outside, base, & behind)</li>
            <li>Disinfect toilet (inside, outside, base, & behind)</li>
            <li>Clean+disinfect+polish sink</li>
            <li>Clean+disinfect+polish faucet</li>
            <li>Fold and hang towels neatly</li>
          </ul>
        </div>
        <div class="col-sm-3">
          <div class="heading">
            <h5>Bedroom</h5>
          </div>
          <ul>
            <li>Make beds (linens changed upon request, must be left out on bed)</li>
          </ul>
        </div>
        <h5 class="mt-5">What if a standard clean is not enough?:</h5>
        <p>
          The standard clean service includes all cleaning supplies necessary to get the
          job done- saving you time at the store and money at the checkout counter. If you
          have a preference toward eco or non-eco friendly products, please let us know
          when you schedule your first appointment, and we will work to accommodate your
          request. Please note, eco-friendly cleaning solutions are great at disinfecting
          surfaces during standard cleans, but may not be strong enough to remove strong
          discolorations or bad cases of mold. While the standard clean is generally
          enough to keep your spaces looking and smelling fresh, a deep clean (hyperlink)
          service is sometimes necessary.
        </p>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  path: "StandardClean",
  component: {},
};
</script>
<style></style>

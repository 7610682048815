<template>
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            class="navbar-nav mx-auto mb-2 mb-lg-0 d-flex justify-content-center nav-ul-li w-100"
          >
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <!-- <li class="nav-item">
                  <a class="nav-link" href="#review">Home</a>
                </li> -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul
                class="dropdown-menu"
                style="width: 17rem"
                aria-labelledby="navbarDropdown"
              >
                <router-link to="/aboutUS" class="nav-link"
                  >Welcome</router-link
                >
                <router-link to="/OurProfessionalCleaners" class="nav-link"
                  >Our Professional Cleaners and Careers</router-link
                >
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/deepClean" class="dropdown-item"
                  >Deep Clean</router-link
                >
                <router-link to="/StandardClean" class="dropdown-item"
                  >Standard Clean</router-link
                >
                <router-link to="/MoveOut" class="dropdown-item"
                  >Move Out and Move in Clean</router-link
                >
                <router-link to="/VacationRentalClean" class="dropdown-item"
                  >Vacation Rental Clean (Airbnb,Vrbo,etc.)</router-link
                >
                <router-link to="/VirusandJerms" class="dropdown-item"
                  >Virus and Germs Disinfection + Sanitization</router-link
                >
                <router-link to="/servicenotoffered" class="dropdown-item"
                  >Services Not Offered</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#review">Reviews</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client Resources
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/faqs" class="dropdown-item p-2"
                  >FAQs</router-link
                >

                <router-link to="/checkoutListing" class="dropdown-item p-2"
                  >Cleaning Checklists</router-link
                >
                <router-link to="/privacy-policy" class="dropdown-item p-2"
                  >Privacy Policy</router-link
                >
                <router-link to="/terms" class="dropdown-item p-2"
                  >Terms of Service</router-link
                >
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link"
                >Contact Us</router-link
              >
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/client/home/login"
              >
                <button class="btn login-col" type="button">Login</button>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="https://cleaninfluencedmv.client-booking.com/index.php/home/start"
              >
                <button class="btn book-col" type="button">Book</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <h1 class="mt-4 cleaning">Who will be cleaning my home?</h1>
    <p>
      Clean Influence - DMV’s professional cleaners have passed in-person
      interviews, background checks, drug screenings, and performed multiple
      test cleans. They have all been ARCSI trained and certified. Our
      professional cleaners are also bonded and insured.
    </p>
    <p>
      We understand the value of building relationships. We take pride in being
      able to provide our clients their preferred cleaning crew about 80% of the
      time. However, life happens and we ask clients for flexibility. If your
      preferred crew is not available, we promise that a crew of other cleaning
      professionals will be able to provide the same level of exceptional
      service you are accustomed to.
    </p>
    <p>
      You can feel confident that we are sending trustworthy individuals to
      service you and that they are the best at what they do. In fact, we
      guarantee it! Our 100% satisfaction guarantee means that if our
      professional cleaners ever do a less than satisfactory job, you should
      contact us and we will be more than willing to come and re-clean, free of
      charge. If you are happy with our cleaning services, please leave us
      reviews on Yelp (hyperlink) or Google (hyperlink). You can also refer us
      to your friends and family or buy them a gift card (hyperlink).
    </p>
    <p>
      Due to the current global pandemic, all professional cleaners are required
      to wear face masks while performing services. This for their safety and
      yours. We ask that you show the same courtesy and contact us immediately
      if you and/or a household member are showing any symptoms of being ill.
    </p>
    <p>
      Our cleaning professionals should not be disrespected, threatened,
      assaulted or harassed in any way. Failure to respect our cleaning
      professionals is grounds for Clean Influence - DMV to deny you service.
    </p>
    <h1 class="mt-4 cleaning">Spekless Cleaning: Careers - Home Cleaning Professional</h1>
    <p>Clean Influence - DMV owes all its success to our dedicated professional cleaners. We have cultivated an incredible team and are always looking for new individuals to work and grow with. We are highly rated because we take exemplary care of our clients AND employees.

</p>

  </div>
</template>

<script>
export default {
  path: "OurProfessionalCleaners",
};
</script>

<style>
.cleaning{
  text-align:start;
  font-size:22px;
  font-weight:600;
}
</style>
